import { Switch, Route, Redirect } from "react-router-dom";
import DetailsPage from "../Screens/LoginRegister/DetailsPage";
import LoginRoot from "../Screens/LoginRegister/LoginRoot";
import GetCalibCertDetails from "../Screens/LoginRegister/ShowCalibCert";
import Error from "../Screens/Error/Error404";

export const LoginRoute = (
    <Switch>
        <Route exact path="/" render={() => <LoginRoot />} />    
        <Route exact path="/GetDetails" render={() => <DetailsPage />} />
        <Route exact path="/QRScanCalibrationCertificateFTP" render={() => <GetCalibCertDetails />} />
        <Redirect from="/signin-oidc*" to="/" />
        <Route render={() => <Error />} />
    </Switch>
);
