export const authUserAction = (gid) => {
  return {
    type: "myIDLogin",
    payLoad: gid,
  };
};
export const saveAccessTokenAction = (token) => {
  return {
    type: "saveAccessToken",
    payLoad: token,
  };
};
export const storeUserDetailsAction = (userData) => {
  return {
    type: "storeUserData",
    payLoad: userData,
  };
};
export const storeUpdatePotentialResponse = (response) => {
  return {
    type: "updatePotential",
    payLoad: 1,
  };
};
export const storeUpdateBudgetResponse = (response) => {
  return {
    type: "updateBudget",
    payLoad: 2,
  };
};
export const storeUpdateManualIndirectResponse = (response) => {
  return {
    type: "updateManualIndirect",
    payLoad: 3,
  };
};
export const errorMessageAction = (errorMessage) => {
  return {
    type: "errorMessage",
    payLoad: errorMessage,
  };
};
export const uploadImageRadioAction = (radioValue) => {
  return {
    type: "radioValue",
    payLoad: radioValue,
  };
};

export const saveAzureTokenAction = (token) => {
    return {
        type: "saveAzureToken",
        payLoad: token,
    };
};
//guidAction
export const guidAction = (guidValue) => {
    return {
        type: "guidValue",
        payLoad: guidValue,
    };
};
export const detailsLinkAction = (saveDetailsLink) => {
    return {
        type: "saveDetailsLink",
        payLoad: saveDetailsLink,
    };
};
export const saveUserIdAction = (id) => {
    return {
        type: "encryptedId",
        payLoad: id,
    };

};

export const storeMenuItemsAction = (menuItems) => {
    return {
        type: "saveLandingMenuItems",
        payLoad: menuItems,
    };
};