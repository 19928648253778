import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DrawerMenuIcon from "../../assets/icons/Menu.svg";
import SiemensLogoWhite from "../../assets/images/siemens-logo-white.svg";
import HomeIcon from "@material-ui/icons/Home";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import SecurityIcon from '@material-ui/icons/Security';
import { storeMenuItemsAction } from "../../Redux/Actions/actions";
import { getAllRoles } from "../../APIs/api_Login";


import "./SideDrawer.scss";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles({
    list: {
        width: 300,
    },
    fullList: {
        width: "auto",
    },
});

export default function SideDrawer() {
    const dispatch = useDispatch();
    let userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    const classes = useStyles();
    const [drawerState, setDrawerState] = React.useState({
        left: false,
    });
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const landingMenuItems = useSelector(state => state.storeLandingMenuItemsReducer.menuItems) || [];
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerState({ ...drawerState, [anchor]: open });
    };

    function logoutUser() {
        var newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
        window.history.pushState({ path: newurl }, "", `/`);
        localStorage.clear();
        sessionStorage.clear();
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });

        window.location.href = "/";
    }

    function homeClick() {
        window.location.href = "/";
    }

    // Electrification
    const sideDrawerItems1 = [
        { icon: <HomeIcon />, title: "Home", link: "/" },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Ecard@EAservices",
            link: "/fileUpload",
        },
        {
            icon: <SecurityIcon />,
            title: "Administration",
            link: "/admin",
        },
        {
            icon: <PowerSettingsNewOutlinedIcon />,
            title: "Logout",
            link: "logout",
        },
    ];

    const sideDrawerItemsExt = [
        { icon: <HomeIcon />, title: "Home", link: "/" },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Ecard@EAservices",
            link: "/fileUploadExternal",
        },
        {
            icon: <PowerSettingsNewOutlinedIcon />,
            title: "Logout",
            link: "logout",
        },
    ];

    // QR Caliberation
    const sideDrawerItems2 = [
        { icon: <HomeIcon />, title: "Home", link: "/qrhome" },
        //{
        //  icon: <ListAltOutlinedIcon />,
        //  title: "Calibration Certificate Upload",
        //  link: "/caliberationUpload",
        //},
        {
            icon: <ListAltOutlinedIcon />,
            title: "Calibration Certificate",
            link: "/caliberationUpload",
        },

        {
            icon: <PowerSettingsNewOutlinedIcon />,
            title: "Logout",
            link: "logout",
        },
    ];

    const icons = {
        "<ListAltOutlinedIcon />": <ListAltOutlinedIcon />,
        "<HomeIcon />": <HomeIcon />,
        "<SecurityIcon />": <SecurityIcon />,
        "<PowerSettingsNewOutlinedIcon />": <PowerSettingsNewOutlinedIcon />
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Link to="/" className="d-flex jc-center side-drawer-logo">
                <img src={SiemensLogoWhite} alt="Siemens" className="official-logo" />
            </Link>
            <Link style={{ marginTop: '-10px' }} >
                <ListItem button onClick={homeClick} >
                    <ListItemIcon className="side-drawer-icons">
                        < HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
            </Link>
            <List className="side-drawer-menu-list" style={{ marginTop: '-10px' }}>
                {landingMenuItems.map((item, index) =>
                (
                    <Link to={item.menuLink} key={index}>
                        <ListItem button>
                            <ListItemIcon className="side-drawer-icons">
                                {icons[item.menuIcon]}
                            </ListItemIcon>
                            <ListItemText primary={item.menuName} />
                        </ListItem>
                    </Link>
                )
                )}
                <ListItem button onClick={logoutUser} >
                    <ListItemIcon className="side-drawer-icons">
                        < PowerSettingsNewOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>

            <div className="sideDrawer-footer-block">
                <p>© 2021 Siemens Intranet</p>
            </div>
        </div>
    );

    const id = localStorage.getItem("ApplicationId");

    function getMenus() {
        getAllRoles(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId
        )
            .then((response) => {
                if (response) {
                    console.log(response)
                    dispatch(storeMenuItemsAction(response));
                } else {
                    dispatch(storeMenuItemsAction([]));
                }
            })
            .catch((error) => {
                console.log("Error: " + error, "error");
            });
    }
    useEffect(() => {
       if (userDetails?.userId > 0 && siteToken !== "" && landingMenuItems?.length <= 0) {
            getMenus();
        }
    }, [userDetails]);
    return (
        <React.Fragment>
            {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <img
                        src={DrawerMenuIcon}
                        alt="Besys Menu"
                        className="besys-menu-icon"
                        onClick={toggleDrawer(anchor, true)}
                    />
                    <SwipeableDrawer
                        anchor={anchor}
                        open={drawerState[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}
