import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { tableIconsInputScreen } from "../../../Utilities/Utility";
import Template from "../../../assets/template/QRCalibrationCertificate-Template.xlsx";
import {
    uploadBaseCalibrationDetails,
    getAllCalibrationCandidateDetails,
} from "../../../APIs/api_ViewDetails";
import { useSelector } from "react-redux";
import {
    FormControlLabel,
    Radio,
    RadioGroup
} from "@material-ui/core";
import "./QRInput.scss"
import Loading from "../../../Components/Loader/Loader";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import * as FileSaver from "file-saver";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DownloadIcon from "@material-ui/icons/ArrowDownwardOutlined";
import UploadIcon from "@material-ui/icons/ArrowUpwardOutlined";
import { uploadImageRadioAction } from "../../../Redux/Actions/actions";
import { useDispatch } from "react-redux";
import { baseApiURL, downloadPDFCertificate, uploadPDFCertificate, attachQRInPDF } from "../APIs/api_QRCalib";
import axios from "axios";
import * as XLSX from "xlsx";
const data = [
    {
        id: 1,
        firstname: "9919701709",
        lastname: "10-Mar-2021",
        email: "Completed",
        product: "Material",
        ucr: "1234UCR1",
        value: "10000",
        doc: "1234567",
        subtype: "Raw Material",
    },
    {
        id: 2,
        firstname: "9919701708",
        lastname: "10-Aug-2021",
        email: "Pending",
        product: "Material",
        ucr: "1234UCR1",
        value: "10000",
        doc: "1234567",
        subtype: "Raw Material",
    },
    {
        id: 3,
        firstname: "9919701707",
        lastname: "10-June-2021",
        email: "Pending",
        product: "Material",
        ucr: "1234UCR1",
        value: "10000",
        doc: "1234567",
        subtype: "Raw Material",
    },
];

export default function QrInputs() {
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedValue, setSelectedValue] = useState("");
    const [selectValue, setSelectValue] = useState("");
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const dispatch = useDispatch();
    const handleChange = (event) => {
        const selValue = event.target.value;
        setSelectedValue(selValue);
        dispatch(uploadImageRadioAction(selValue));
        console.log("Val", selValue);
    };
    const handleChangeSelect = (event) => {
        const value = event.target.value;
        setSelectValue(value);
        dispatch(uploadImageRadioAction(value));
        console.log("Value", value);
    };
    const [invoiceData, setInvoiceData] = useState([]);
    let [isLoader, setIsLoader] = useState(false);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const token = useSelector((state) => state.saveTokenReducer.token);
    const updateExcelHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const uploadType = useSelector(
        (state) => state.uploadImageRadioReducer.value
    );
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const handleDialogMessage = (text) => {
        setDialogContent(text);
        setDialogOpen(true);
    };
    let [errorData, setErrorData] = useState([]);
    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        if (
            typeof dialogContent === "string" &&
            dialogContent.toLowerCase().includes("success")
        ) {
            setInputFileValue(Date.now);
            setSelectedFile(null);
        }
        setDialogOpen(false);
        setOpen(false);
        setInputFileValue(Date.now);
        setErrorData([]);
    };
    const exportErrorData = () => {
        console.log("Export");
        ExportErrorDataToExcel();
    };

    function ExportErrorDataToExcel() {
        const dataToDownload = errorData.map((row) => {
            console.log(row);
            return {

                assetId: row.assetId,
                serialNumber: row.serialNumber,
                ulrNumber: row.ulrNumber,
                srfNo: row.srfNo,
                remarks: row.remarks?.replace(".", ".\n "),

            };
        });
        let headers = ["Asset ID", "Serial Number", "ULR Number","SRF Number",
            "Remarks",

        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContentsCustomer(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "ErrorData");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(wb, "ExcelUploadErrors.xlsx");
    }
    function cellAdjustToContentsCustomer(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.assetId ? c.assetId?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.serialNumber ? c.serialNumber?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.ulrNumber ? c.ulrNumber?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.srfNo ? c.srfNo?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.remarks ? c.remarks?.toString()?.length : 0))
                ),
            },
        ];
    }


    // File Upload Part 
    const [certInputFileValue, setCertInputFileValue] = useState(Date.now);
    const [certFileDialogOpen, setCertFileDialogOpen] = useState(false);
    const [currentRowData, setCurrentRowData] = useState(null);
    const [isFileUploadedFlag, setIsFileUploadedFlag] = useState(false);
    const [uploadCertFile, setUploadCertFile] = useState([]);
    const [assetId, setAssetId] = useState("");
    const [serialNo, setSerialNo] = useState("");
    const [isFlag, setIsFlag] = useState(false);
    const [isUpload, setIsUpload] = useState(true);

    const handleCertFileUploadOpen = (rowData) => {
        setAssetId(rowData.assetId);
        /*console.log("asset", assetId);*/
        setSerialNo(rowData.serialNumber);
        /*console.log("serialNo", serialNo);*/
        console.log("file", rowData.isFileUploaded);
        if (rowData.isFileUploaded === 1) {
            setIsFileUploadedFlag(true);
            console.log("FileUploadedValue", isFileUploadedFlag);
        }
        else {
            setIsFileUploadedFlag(false);
        }

        if (rowData.isFileUploaded == 2) {
            setIsFlag(true);
        }
        else {
            setIsFlag(false);
        }

        dispatch(uploadImageRadioAction(""));
        setCurrentRowData(rowData);
        setUploadCertFile([]);
        /*setCertFileDialogOpen(true);*/
        dialogOpenBox();
    };
    console.log(isUpload);
    const dialogOpenBox = () => {
        setCertFileDialogOpen(true);
    }
    const handleCertFileUploadClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setSelectValue("");
        setSelectedValue("");
        savingFlagValue1();
        console.log("box", selectValue);
        setCertFileDialogOpen(false);
    };
    const updateCertFileHandler = (e) => {
        const chosenFile = Array.prototype.slice.call(e.target.files);
        if (chosenFile.length > 0) {
            if (chosenFile[0].type.toString().includes("pdf")) {
                setUploadCertFile(chosenFile);
            } else {
                setCertInputFileValue(Date.now);
                setUploadCertFile([]);
                handleSnackOpen("Upload only PDF files", "warning");
            }
        }
    };

    const uploadCertOnClick = () => {

        if (uploadType == "" || uploadType === undefined) {
            handleSnackOpen("Select a type of Certificate [Base or Final].", "warning");
            return;
        } else if (uploadCertFile.length <= 0) {
            handleSnackOpen("Select a Certificate.", "warning");
            return;
        }
        if (selectedValue == "" && selectValue == "OU") {
            handleSnackOpen("select Base Upload ", "warning");
            return
        }
        if (selectedValue == "" && selectValue == "AU") {
            handleSnackOpen("select Base Upload ", "warning");
            return
        }
        const isAmmendedOrOverwritten = localStorage.getItem("IsAmmendedOrOverwritten");
        console.log(isAmmendedOrOverwritten);
        const isBaseOrFinal = localStorage.getItem("IsBaseOrFinal");
        console.log(isBaseOrFinal);
        setSelectValue("");
        setSelectedValue("");
        setCertFileDialogOpen(false);
        setIsLoader(true);
        const formData = new FormData();
        formData.append("file", uploadCertFile[0]);
        formData.append("userId", userDetails.userId);
        formData.append("calibrationId", currentRowData.calibrationId);
        formData.append("srfNo", currentRowData.srfNo);
        formData.append("UploadType", uploadType);
        formData.append("IsAmmendedOrOverwritten", isAmmendedOrOverwritten);
        formData.append("IsBaseOrFinal", isBaseOrFinal);
        formData.append("serialNumber", currentRowData.serialNumber);
        formData.append("assetId", currentRowData.assetId);
        formData.append("status", currentRowData.status);
        formData.append("isFileUploaded", currentRowData.isFileUploaded);
        formData.append("ulrNumber", currentRowData.ulrNumber);
        savingFlagValue1();

        uploadPDFCertificate(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                if (response.response) {
                    const downloadArgs = {
                        fileUrl: response.objResult.fileUrl,
                        assetId: response.objResult.assetId,
                        srfNo: response.objResult.srfNo,
                        ulrNumber: response.objResult.ulrNumber,
                        isQradded: response.objResult.isQradded,
                        isFileUploaded: response.objResult.isFileUploaded,
                        serialNumber: response.objResult.serialNumber
                    }
                    generateQrwithPdf(downloadArgs);
                    //setIsLoader(false);
                    //handleCertFileUploadClose();
                    //handleSnackOpen(response.responseMsg, "success");
                    //getCalibrationCandidateData();
                }
                else {
                    setIsLoader(false);
                    handleCertFileUploadClose();
                    handleSnackOpen("Certificate Upload Failed", "error")
                }
            })
            .catch((error) => {
                handleSnackOpen(error.responseMsg, "error");
                setIsLoader(false);
            });
    };

    function generateQrwithPdf(downloadArgs) {
        attachQRInPDF(siteToken, azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, downloadArgs)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen("Certificate Uploaded successfully ", "success");
                    getCalibrationCandidateData();
                } else {
                    setIsLoader(false);
                    handleSnackOpen("Certificate Upload failed.", "error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen("Error while uploading certificate.", "error");
            })
    }

  
    const handleCertFileDownload = (rowData) => {
        setIsLoader(true);
        if (rowData.fileUrl == null) {
            setIsLoader(false);
            handleSnackOpen("Certificate Not Uploaded", "warning");
            return false;
        }

        const downloadArgs = {
            fileUrl: rowData.fileUrl,
            assetId: rowData.assetId,
            srfNo: rowData.srfNo,
            ulrNumber: rowData.ulrNumber,
            isFileUploaded: rowData.isFileUploaded,
            serialNumber: rowData.serialNumber
        }

        downloadPDFCertificate(siteToken, azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, downloadArgs)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.byteLength <= 200) {
                        setIsLoader(false);
                        handleSnackOpen("No Records are Found", "success");
                    } else {
                        const fileNameField = response.config.data;
                        const splitStr1 = fileNameField.split(",");
                        const splitStr2 = splitStr1[0].split("/");
                        const fileNameExt = splitStr2[splitStr2.length - 1];
                        const fileName = fileNameExt.split(".");
                        // const start = fileNameField.lastIndexOf("/") + 1;
                        // const end = fileNameField.lastIndexOf(".pdf");
                        // const fileName = fileNameField.substring(start, end);
                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });
                        FileSaver.saveAs(blob, fileName[0] + ".pdf");
                        setIsLoader(false);
                        handleSnackOpen("Certificate with QR downloaded.", "success");
                    }
                } else {
                    setIsLoader(false);
                    handleSnackOpen("Certificate download failed.", "error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen("Error while downloading certificate.", "error");
            })
    }
    const columns = [
        { title: "Id", field: "id", hidden: true },
        { title: "Asset Id", field: "assetId"/*, validate:rowdata => rowdata.assetId.length === 0 && rowdata.serialNumber.length === 0 ? "Assetid/SerialNo. Either one is Mandatory!" : ""*/},
        { title: "Serial No", field: "serialNumber" },
        { title: "ULR No", field: "ulrNumber" },
        {
            title: "SRF No",
            field: "srfNo",
            editable: "never" ,
        },
        {
            title: "File Upload Status",
            field: "fileUploadStatus",
            editable: "never" ,
        },
        {
            title: "Upload Certificate",
            field: "gid",
            editable: false,
            render: (rowData) => (
                <Button>
                    <UploadIcon
                        className="checkIcon"
                        onClick={() => handleCertFileUploadOpen(rowData)}

                    />
                </Button>
            ),
        },
        {
            title: "Download File",
            field: "product",
            editable: false,
            render: (rowData) => (
                <Button>
                    <DownloadIcon
                        className="checkIcon"
                        onClick={() => handleCertFileDownload(rowData)}
                    />
                </Button>
            ),
        },
        {
            title: "View Detail",
            width: "10%",
            render: (rowData) => viewDetailButton(rowData),
        },
    ];

    const openCalibPDF = (rowData) => {
        setIsLoader(true);
        const options = {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }
        axios.get(baseApiURL + "/api/Candidate/ViewCalibrationCertificate?SrfNo=" + rowData.srfNo, options)
            .then((response) => {
                setIsLoader(false);
                if (response.status === 200)
                    if (response.data.result.fileUrl !== null) {
                        window.open(response.data.result.fileUrl, "_blank");
                        handleSnackOpen("PDF File Opened in New Tab", "success");
                    }
                    else {
                        handleSnackOpen("PDF File Not Found", "warning");
                    }
                else
                    handleSnackOpen("PDF File Not Found", "error");
            })
            .catch((err) => {
                setIsLoader(false);
                handleSnackOpen(err, "error");
            });
    };

    const viewDetailButton = (rowData) => {
        return (
            <Button
                className="pt-button--secondary"
                onClick={() => openCalibPDF(rowData)}
            >
                View Detail
            </Button>
        );
    };
    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {

        const apiOptions = {
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + siteToken.token,
                UserEmail: userDetails.userEmail,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: azureToken.token,
                RoleName: userDetails.roleName,
            },
        };

        axios
            .post(
                baseApiURL + "/api/Candidate/UpdateCalibrationDetails?EncuserId=" + encodeURIComponent(UID.id),
                newTableData,
                apiOptions
            )
            .then((res) => {
                const dataUpdate = [...invoiceData];
                if (res.status == 200) {
                    if (res.data.result == "Update Successfull") {
                        const index = oldTableData.tableData.id;
                        dataUpdate[index] = newTableData;
                        setInvoiceData([...dataUpdate]);
                        //resolve();
                        reject();
                        handleSnackOpen("Updated Successfully", "success");
                    }
                    else {
                        reject();
                        handleSnackOpen(res.data.result, "error");
                    }
                }
                else {
                    reject();
                    handleSnackOpen(res.data.result, "error");
                }

            })
            .catch((error) => {
                handleSnackOpen("Record update failed.", "error");
                reject();
            });
    };
    const handleRowDelete = (oldTableData, resolve, reject) => {
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + siteToken.token,
                UserEmail: userDetails.userEmail,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: azureToken.token,
                RoleName: userDetails.roleName,
            },
            //body: JSON.stringify(data),
        };

        fetch(
            baseApiURL + "/api/Candidate/DeleteCalibrationDetails?EncuserId=" + encodeURIComponent(UID.id) +"&srfNo=" + oldTableData.srfNo,
            options
        )
            .then((response) => response.json())
            .then((data) => {
                const dataDelete = [...invoiceData];
                const index = oldTableData.tableData.id;
                dataDelete.splice(index, 1);
                setInvoiceData([...dataDelete]);
                //resolve();
                reject();
                handleSnackOpen("Record deleted successfully.", "success");

            })
            .catch((error) => {
                handleSnackOpen("Record deletion failed.", "error");
            });
    };

    //function flag() {
    //    if (selectedValue != "FU" && selectedValue == "") {
    //        setIsFlag(false);
    //    }
    //    else {
    //        setIsFlag(true);
    //    }
    //    console.log("FileUploadValue", isFlag);
    //}
    function savingFlagValue1() {
        console.log(1);
        localStorage.setItem("IsAmmendedOrOverwritten", 1);
    }
    function savingFlagValue2() {
        console.log(2);
        localStorage.setItem("IsAmmendedOrOverwritten", 2);
    }
    function savingFlagValue3() {
        console.log(3);
        localStorage.setItem("IsAmmendedOrOverwritten", 3);
    }
    function savingFlagValue4() {
        console.log(3);
        localStorage.setItem("IsBaseOrFinal", 1);
    }
    function savingFlagValue5() {
        console.log(3);
        localStorage.setItem("IsBaseOrFinal", 2);
    }

    function getCalibrationCandidateData() {
        setIsLoader(true);
        getAllCalibrationCandidateDetails(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                setInvoiceData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Details fetching failed.", "error");
                // setSelectedFile(false);
            });
    }

    function uploadCalibrationDetailsFile() { 
        setIsLoader(true);
        var formdata = new FormData();
        formdata.append("file", selectedFile);
        formdata.append("UserId", userDetails.userId);
        uploadBaseCalibrationDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formdata)
            .then((response) => {
                if (response.response === true) {
                    setInputFileValue(Date.now);
                    handleDialogMessage(response.responseMsg);
                    getCalibrationCandidateData();
                    setSelectedFile("");


                } else {
                    setIsLoader(false);
                    setInputFileValue(Date.now);
                    setErrorData(response.objResult);
                    handleDialogMessage(
                        response.responseMsgs && response.responseMsgs.length > 0
                            ? response.responseMsgs
                            : response.responseMsg
                    );
                  /*  handleSnackOpen("Error while uploading Excel.", "error");*/
                    setSelectedFile("");

                }
            })
            .catch((error) => {
                setSelectedFile("");
                setInputFileValue(Date.now);
                setIsLoader(false);
                handleDialogMessage("Exception in upload");
                alert("error", error);
            });
    }
    useEffect(() => {
        getCalibrationCandidateData();
        savingFlagValue1();
    }, []);

    return (
        <>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Calibration Certificate </h3>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            label="Upload File"
                            variant="filled"
                            className="file-upload"
                            //{...register("bulkUpload")}
                            type="file"
                            name="fileUpload"
                            onChange={updateExcelHandler}
                            key={inputFileValue}
                            InputLabelProps={{ shrink: true }}
                        />

                    </Grid>
                    <Grid item xs={6} sm={4} md={2} lg={1}>
                        <Button
                            className="pt-button--secondary bulk-upload-submit-btn"
                            type="button"
                            disabled={selectedFile ? false : true}
                            onClick={uploadCalibrationDetailsFile}
                        >
                            Upload
                        </Button>
                    </Grid>

                    <Grid container item xs={12} md={6} className="d-flex jc-flex-end">
                        <Grid item xs={12} md={6} className="d-flex jc-flex-end">
                            <Link to={Template} target="_blank" download>
                                <Button
                                    className="pt-button--secondary bulk-upload-submit-btn"
                                    type="button"
                                >
                                    Download Template
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="mt-05 tableCont">
                        <MaterialTable
                            title="Calibration List"
                            className="User List"
                            columns={columns}
                            data={invoiceData}
                            icons={tableIconsInputScreen}
                            isLoading={isLoader}
                            options={{
                                exportButton: {
                                    csv: true,
                                    pdf: false,
                                },
                                search: true,
                                exportAllData: true,
                                exportFileName: "Calibration List",
                            }}
                            editable={{
                                onRowUpdate: (newTableData, oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowUpdate(newTableData, oldTableData, resolve, reject);
                                    }),
                                onRowDelete: (oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowDelete(oldTableData, resolve, reject);
                                    }),
                            }}
                        />
                    </Grid>

                </Grid>
            </div>
            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />

            <Dialog
                width={"sm"}
                fullWidth={"lg"}
                maxWidth={"sm"}
                open={certFileDialogOpen}
                onClose={handleCertFileUploadClose}
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Upload Certificate</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid xs={12} item>
                            <div className="d-flex jc-center"><span>Asset Id: {assetId} </span>  <span className="pl-3">Serial No:{serialNo}</span> </div>
                        </Grid>
                        <Grid xs={12} container>

                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                value={selectedValue}
                                onChange={handleChange}

                            >
                                <FormControlLabel
                                    value="BU"
                                    control={<Radio color="primary" />}
                                    label="Base Upload"
                                    labelPlacement="end"
                                    onChange={savingFlagValue4}
                                />
                                <div className="fu">
                                    <FormControlLabel
                                        value="FU"
                                        control={<Radio color="primary" />}
                                        label="Final Upload"
                                        labelPlacement="end"
                                        disabled={!isFileUploadedFlag}
                                        onChange={savingFlagValue5}
                                    />
                                </div>
                            </RadioGroup>
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                value={selectValue}
                                onChange={handleChangeSelect}

                            >
                                <div className="ou">
                                    <FormControlLabel
                                        value="OU"
                                        control={<Radio color="primary" />}
                                        label="Overwrite"
                                        labelPlacement="end"
                                        disabled={!isFlag}
                                        onChange={savingFlagValue3}
                                    />
                                </div>
                                <FormControlLabel
                                    value="AU"
                                    control={<Radio color="primary" />}
                                    label="Amend"
                                    labelPlacement="end"
                                    disabled={!isFlag}
                                    onChange={savingFlagValue2}
                                />

                            </RadioGroup>

                        </Grid>
                        <Grid xs={12} container>
                            <Grid item xs={12} sm={6} md={8}>
                                <form className="file-upload-form">
                                    <TextField
                                        type="file"
                                        label="Upload File"
                                        variant="filled"
                                        className="file-upload"
                                        name="certFileUpload"
                                        onChange={updateCertFileHandler}
                                        key={certInputFileValue}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Button
                                    className="pt-button--primary bulk-upload-submit-btn"
                                    onClick={uploadCertOnClick}
                                    fullWidth
                                >
                                    Upload
                                </Button>
                            </Grid>
                        </Grid>
                        <span>Upload only pdf file.</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid xs={12} sm={6} md={6}>
                        <Button
                            onClick={handleCertFileUploadClose}
                            className="pt-button--secondary bulk-upload-submit-btn"
                            fullWidth
                        >
                            Close
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {" Data Upload Alert"}
                    </DialogTitle>
                    <DialogContent>
                        {dialogContent && typeof dialogContent === "object" ? (
                            <DialogContentText id="alert-dialog-description">
                                <h5>
                                    {dialogContent
                                        ?.filter((x, index) => index === 0)
                                        ?.map((c) => (
                                            <>&emsp;&emsp;{c.join(" ")}</>
                                        ))}
                                </h5>
                                <ol className="ol-bulkUpload-Error">
                                    {dialogContent
                                        ?.filter((x, index) => index !== 0)
                                        ?.map((c) => (
                                            <li>
                                                {c.map((x) =>
                                                    x.includes("--") && x.includes(":") ? (
                                                        x
                                                    ) : (
                                                        <ul className="ul-bulkUpload-Error">
                                                            <li>{x}</li>
                                                        </ul>
                                                    )
                                                )}
                                            </li>
                                        ))}
                                </ol>
                            </DialogContentText>
                        ) : (
                            <DialogContentText
                                id="alert-dialog-description "
                                className="dialog-center"
                            >
                                {dialogContent?.includes("successfully.") ? (
                                    <>
                                        {dialogContent?.split("successfully.")[0] +
                                            " successfully."}
                                        {dialogContent?.split("successfully.")?.length > 0 ? (
                                            <>
                                                <br />
                                                {dialogContent?.split("successfully.")[1]}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                ) : (
                                    dialogContent
                                )}
                            </DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            className="pt-button--secondary"
                            title="Close"
                        >
                            Close
                        </Button>
                        {errorData && errorData.length > 0 ? (
                            <Button
                                onClick={exportErrorData}
                                className="pt-button--secondary"
                                title={
                                    "Export   upload error data to excel"
                                }
                            >
                                Export Error Data
                            </Button>
                        ) : (
                            <></>
                        )}
                    </DialogActions>
                </Dialog>
               {/* {isLoader ? <Loading loaderText="Loading...." /> : <></>}*/}
            </div>
            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </>


    );
}
