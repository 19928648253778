import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Link, Button, Container } from "@material-ui/core";
import "../LoginRegister/LoginRegister.scss";
import OfficialLogo from "../../assets/images/siemens-logo-petrol.svg";
import { useDispatch } from "react-redux";
import { useAuth } from "react-oidc-context";
import { loginRequest } from "../../authConfig";
import { useMsal } from '@azure/msal-react';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export default function LoginRegister() {
  const auth = useAuth();
  const classes = useStyles();
    const dispatch = useDispatch();
    const { instance } = useMsal();

  function myIDLogin() {
    dispatch(auth.signinRedirect);
    }
    function azureADLogin() {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
        // dispatch(auth.signinRedirect);
    }

  return (
    <div className="bg-login-register">
      <div className={classes.root}>
        <Container maxWidth="xl">
          <Grid item xs={12}>
            <div className="litigation-logo">
              <img src={OfficialLogo} className="official-logo" alt="Siemens" />
            </div>
          </Grid>
          <Grid container className="litigation-mobileContainer">
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              className="litigation--project-block"
            >
              <div className="litigation--project">
                <p className="litigation-project-name">
                  Smart QR
                </p>
                {/* <span class="litigation--project-description">
                  SI EP India Learning Portal
                </span> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={10} md={5} className="pt-lr-master-block">
              <div className="pt-lr-block">
                <div className="pt-login-siemens_user">
                  <h3 className="m-0">Siemens/External Employee Login </h3>
                  <span>If you are a Siemens/External Employee, login via Azure AD</span>
                                  <div className="d-flex jc-center mt-1">
                                      <Link to="/">
                                          <Button
                                              className="pt-button--hero myid-login-btn-block"
                                              id="AzureAdLogin"
                                              onClick={azureADLogin}
                                          >
                                              <span>Azure AD Login</span>
                                          </Button>
                                      </Link>
                                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
