import {
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Avatar,
  Tooltip,
  TextareaAutosize,
  InputLabel,
  InputBase,
  Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import SampleAvatar from "../../assets/images/sample-avatar.png";
import { alpha, withStyles } from "@material-ui/core/styles";
import FormDatePicker from "../../Components/DatePicker/DatePicker";
import { getCandidateDetails } from "../../APIs/api_ViewDetails";
import Loading from "../../Components/Loader/Loader";
import * as FileSaver from "file-saver";
import './LoginRegister.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: `center`,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  details: {
    alignSelf: `center`,
  },
  atag: {
    alignSelf: `center`,
    fontStyle: `italic`,
    textDecoration: `underline`,
    display: `flex`,
    justifyContent: `center`,
  },
  ablock: {
    color: `#099 !important`,
  },
  secondaryHeader: {
    margin: 0,
  },
  profilepic: {
    border: `2px double #00BEDC`,
    boxShadow: `0px 2px 10px 5px #00BEDC`,
  },
  profilepicTech: {
    border: `2px double #099`,
    boxShadow: `0px 2px 10px 5px #00FFB9`,
  }
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
      width: `100%`,
    },
  },
  input: {
    borderRadius: 4,
    color: `#000028`,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "2px solid #00B3B3",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const InputLabelText = withStyles((theme) => ({
  root: {
    fontWeight: `bold`,
    fontSize: `18px`,
  },
}))(InputLabel);

export default function DetailsPage() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [pageHeader, setPageHeader] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  let [isLoader, setIsLoader] = useState(false);
  const [salutation, setSalutation] = useState("Mr");
  const [name, setName] = useState("");
  const [employerServicePartner, setEmployerServicePartner] = useState("");
  const [educationalQualification, setEducationalQualification] = useState("");
  const [educationalStream, setEducationalStream] = useState("");
  const [experience, setExperience] = useState("");
  const [aisValidity, setAisValidity] = useState(null);
  const [gisValidity, setGisValidity] = useState(null);
  const [rmuValidity, setRmuValidity] = useState(null);
  const [cssValidity, setCssValidity] = useState(null);
  const [lvdsValidity, setLvdsValidity] = useState(null);
  const [protectionValidity, setProtectionValidity] = useState(null);
  const [ehsValidity, setEhsValidity] = useState(null);
  const [trainingUndergone, setTrainingUndergone] = useState("");
  const [generatedQRCode, setGeneratedQRCode] = useState("");

  const [imageContent, setImageContent] = useState(null);

  const [protectionCompetenceLevel, setProtectionCompetenceLevel] =
    useState("");
  const [fileURL, setFileURL] = useState("");
  const [automationCompetenceLevel, setAutomationCompetenceLevel] =
    useState("");
  const [automationValidity, setAutomationValidity] = useState(null);

  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }
  var encryptedId = getURLParameter("UniqueID");

  function getMasterData() {
    setIsLoader(true);
    let token = "";
    getCandidateDetails(token, encryptedId)
      .then((response) => {
        console.log("Res", response);
        setData(response);
        setImageUrl(response.photoUrl);
        setImageContent(response.photoUrlShow);
        setPageHeader(response.workmanType);
        setSalutation(response.salutation);
        setName(response.name);
        setEmployerServicePartner(response.employerServicePartner);
        setEducationalQualification(response.educationalQualification);
        setEducationalStream(response.educationStream);
        setExperience(response.experienceinNoofyears);
        setAisValidity(response.mvAisValidityTill);
        setGisValidity(response.mvGisValidityTill);
        setRmuValidity(response.rmuValidityTill);
        setCssValidity(response.cssValidityTill);
        setLvdsValidity(response.lvdsValidityTill);
        setProtectionValidity(response.protectionValidityTill);
        setEhsValidity(response.ehsSitrustValidityTill);
        setTrainingUndergone(response.otherTrainings);
        setProtectionCompetenceLevel(response.protectionCompetencyLevelType);
        setFileURL(response.fileUrl);
        setAutomationCompetenceLevel(response.automationCompetenceLevel);
        setAutomationValidity(response.automationValidityTill);
        setGeneratedQRCode(response.qrCode);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    if (encryptedId) {
      getMasterData();
    }
  }, [encryptedId]);

  return (
    <div className="p-2">
      <DialogTitle id="responsive-dialog-title">
        {pageHeader} Details
      </DialogTitle>
      <div className="warningTextContainer">
        <Grid xs={12} md={4} >
          <span>
            ** Authorized only when deputed by Siemens Limited
          </span>
        </Grid>
      </div>
      <Grid container spacing={2}>
        <Grid
          container
          item
          xs={12}
          sm={4}
          md={3}
          spacing={2}
          className={classes.root}
        >
          <div
            className={
              pageHeader == "Engineer"
                ? classes.profilepic
                : pageHeader == "Technician"
                  ? classes.profilepicTech
                  : ""
            }
          >
            {/* <Avatar
              variant="rounded"
              alt="Remy Sharp"
              src={imageUrl}
              className={classes.large}
            /> */}

            <img
              src={`${imageContent}`}
              alt="Profile"
              height="140"
              width="140"
            />
          </div>
        </Grid>
        <Grid container item xs={12} sm={8} md={9} spacing={2}>
          <Grid item xs={12} sm={4} className={classes.details}>
            <InputLabelText shrink htmlFor="bootstrap-input">
              Salutation
            </InputLabelText>
            <BootstrapInput
              //defaultValue={invoiceDetails.type}
              id="salutation"
              value={salutation}
              disabled
              placeholder="Salutation"
            />
          </Grid>

          <Grid item xs={12} sm={4} className={classes.details}>
            <InputLabelText shrink htmlFor="bootstrap-input">
              Full Name
            </InputLabelText>
            <BootstrapInput
              //defaultValue={invoiceDetails.type}
              id="name"
              value={name}
              disabled
              placeholder="Name"
            />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.details}>
            <InputLabelText shrink htmlFor="bootstrap-input">
              Employer Service Partner
            </InputLabelText>
            <BootstrapInput
              //defaultValue={invoiceDetails.type}
              id="employerServicePartner"
              value={employerServicePartner}
              disabled
              placeholder="Employer Service Partner"
            />
          </Grid>

          {/* {generatedQRCode !== null && generatedQRCode !== undefined ? (
            <Grid item xs={12} sm={4} md={3}>
              <Button
                className="pt-button--secondary bulk-upload-submit-btn"
                type="button"
                onClick={() => FileSaver(generatedQRCode, name + "-qr.jpg")}
              >
                Download QR
              </Button>
            </Grid>
          ) : (
            <></>
          )} */}

          {/* <Grid item xs={12} className={classes.atag}>
            <Tooltip title="Click to view employee details">
              <a className={classes.ablock} href="">
                Employee Details URL
              </a>
            </Tooltip>
          </Grid> */}
        </Grid>
      </Grid>
      <hr />
      <h4>Qualification Details</h4>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <InputLabelText shrink htmlFor="bootstrap-input">
            Educational Qualification
          </InputLabelText>
          <BootstrapInput
            //defaultValue={invoiceDetails.type}
            id="educationalQualification"
            value={educationalQualification}
            disabled
            placeholder="Educational Qualification"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <InputLabelText shrink htmlFor="bootstrap-input">
            Educational Stream
          </InputLabelText>
          <BootstrapInput
            //defaultValue={invoiceDetails.type}
            id="educationalStream"
            value={educationalStream}
            disabled
            placeholder="Educational Stream"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <InputLabelText shrink htmlFor="bootstrap-input">
            Experience (in years)
          </InputLabelText>
          <BootstrapInput
            //defaultValue={invoiceDetails.type}
            id="experience"
            value={experience}
            disabled
            placeholder="Experience (in years)"
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h4 className={classes.secondaryHeader}>Training status of trainings provided by Siemens on product & safety</h4>
        </Grid>
        {aisValidity !== null &&
          aisValidity !== undefined &&
          aisValidity !== "" ? (
          <Grid item xs={12} sm={4} md={3} className="posting-date">
            <FormDatePicker
              className=""
              id="aisValidity"
              disabled
              placeholder="&nbsp;&nbsp;&nbsp;MV AIS Validity till"
              label="MV AIS Validity till"
              value={aisValidity}
            />
          </Grid>
        ) : (
          <></>
        )}

        {gisValidity !== null &&
          gisValidity !== undefined &&
          gisValidity !== "" ? (
          <Grid item xs={12} sm={4} md={3} className="posting-date">
            <FormDatePicker
              className=""
              id="gisValidity"
              disabled
              placeholder="&nbsp;&nbsp;&nbsp;MV GIS Validity till"
              label="MV GIS Validity till"
              value={gisValidity}
            />
          </Grid>
        ) : (
          <></>
        )}

        {rmuValidity !== null &&
          rmuValidity !== undefined &&
          rmuValidity !== "" ? (
          <Grid item xs={12} sm={4} md={3} className="posting-date">
            <FormDatePicker
              className=""
              id="rmuValidity"
              disabled
              placeholder="&nbsp;&nbsp;&nbsp;RMU validity till"
              label="RMU validity till"
              value={rmuValidity}
            />
          </Grid>
        ) : (
          <></>
        )}

        {cssValidity !== null &&
          cssValidity !== undefined &&
          cssValidity !== "" ? (
          <Grid item xs={12} sm={4} md={3} className="posting-date">
            <FormDatePicker
              className=""
              id="cssValidity"
              disabled
              placeholder="&nbsp;&nbsp;&nbsp;CSS validity till"
              label="CSS Validity till"
              value={cssValidity}
            />
          </Grid>
        ) : (
          <></>
        )}

        {lvdsValidity !== null &&
          lvdsValidity !== undefined &&
          lvdsValidity !== "" ? (
          <Grid item xs={12} sm={4} md={3} className="posting-date">
            <FormDatePicker
              className=""
              id="lvdsValidity"
              disabled
              placeholder="&nbsp;&nbsp;&nbsp;LVDS validity till"
              label="LVDS Validity till"
              value={lvdsValidity}
            />
          </Grid>
        ) : (
          <></>
        )}

        {ehsValidity !== null &&
          ehsValidity !== undefined &&
          ehsValidity !== "" ? (
          <Grid item xs={12} sm={4} md={3} className="posting-date">
            <FormDatePicker
              className=""
              id="ehsValidity"
              disabled
              placeholder="&nbsp;&nbsp;&nbsp;EHS - SITRUST Validity till"
              label="EHS - SITRUST Validity till"
              value={ehsValidity}
            />
          </Grid>
        ) : (
          <></>
        )}

        {protectionCompetenceLevel !== null &&
          protectionCompetenceLevel !== undefined &&
          protectionCompetenceLevel !== "" ? (
          <Grid item xs={12} sm={4} md={3}>
            <InputLabelText shrink htmlFor="bootstrap-input">
              Protection Competence Level Type
            </InputLabelText>
            <BootstrapInput
              //defaultValue={invoiceDetails.type}
              id="protectionCompetenceLevel"
              value={protectionCompetenceLevel}
              disabled
              placeholder="Protection Competence Level Type"
            />
          </Grid>
        ) : (
          <></>
        )}

        {protectionValidity !== null &&
          protectionValidity !== undefined &&
          protectionValidity !== "" ? (
          <Grid item xs={12} sm={4} md={3} className="posting-date">
            <FormDatePicker
              className=""
              id="protectionValidity"
              disabled
              placeholder="&nbsp;&nbsp;&nbsp;Protection validity till"
              label="Protection Validity till"
              value={protectionValidity}
            />
          </Grid>
        ) : (
          <></>
        )}

        {automationCompetenceLevel !== null &&
          automationCompetenceLevel !== undefined &&
          automationCompetenceLevel !== "" ? (
          <Grid item xs={12} sm={4} md={3}>
            <InputLabelText shrink htmlFor="bootstrap-input">
              Automation Competence Level Type
            </InputLabelText>
            <BootstrapInput
              //defaultValue={invoiceDetails.type}
              id="automationCompetenceLevel"
              value={automationCompetenceLevel}
              disabled
              placeholder="Automation Competence Level Type"
            />
          </Grid>
        ) : (
          <></>
        )}

        {automationValidity !== null &&
          automationValidity !== undefined &&
          automationValidity !== "" ? (
          <Grid item xs={12} sm={4} md={3} className="posting-date">
            <FormDatePicker
              className=""
              id="automationValidity"
              disabled
              placeholder="&nbsp;&nbsp;&nbsp;Automation validity till"
              label="Automation Validity till"
              value={automationValidity}
            />
          </Grid>
        ) : (
          <></>
        )}

        {trainingUndergone !== null &&
          trainingUndergone !== undefined &&
          trainingUndergone !== "" ? (
          <Grid item xs={12} sm={4} md={3}>
            <InputLabelText shrink htmlFor="bootstrap-input">
              Other Trainings List
            </InputLabelText>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Other Trainings List"
              className="trainings"
              value={trainingUndergone}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {isLoader ? <Loading loaderText="Loading...." /> : <></>}
    </div>
  );
}
