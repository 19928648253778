import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import Cognisphere from "../../assets/images/Cognisphere.png";
import Loader from "../../Components/Loader/Loader";
import Header from "../../Components/Header/Header";
import { useSelector } from "react-redux";
import { LandingAdmin, LandingMenu } from "../../Utilities/Utility";

export default function AdminLandingPage() {
  const [routes, setRoutes] = useState([]);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  function getMenus() {
    let filtered = LandingAdmin.filter((c) => c.roleId === 1);
    setRoutes(filtered);
  }

  var newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  window.history.pushState({ path: newurl }, "", newurl);
  useEffect(() => {
    getMenus();
  }, []);

  return (
    <>
      <div className="potts-master-block">
        <Header />
        <main className="main-component">
          <img
            src={Cognisphere}
            alt="Cognisphere"
            className="homepage-cognisphere"
          />
          {routes.length ? (
            <div className="bg-landing-page">
              <Container maxWidth="xl" className="logged-in-user">
                <Grid container className="homepage-container mb-2">
                  <Grid
                    container
                    item
                    xs={12}
                    className="landing-page-menu"
                    spacing={2}
                    style={{ height: `350px` }}>
                    {routes.map((item, index) => (
                      <Grid
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        style={{ height: `0px` }}>
                        <Link to={item.menuLink} key={index}>
                          <div className="menu-item-container">
                            <div className="menu-item-text-block">
                              <span className="align-center ml-1">
                                {item.menuName}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Container>
            </div>
          ) : (
            <Loader />
          )}
        </main>
      </div>
    </>
  );
}
