import React, { useState, useEffect } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { tableIconsInputScreen } from "../../../Utilities/Utility";
import { tableOptionsUser } from "../../../Themes/LightTheme";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getRoles, getUserList, handleSubmit, handleUpdate, handleDelete } from "../../../Redux/API/api_admin";
//import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loader from "../../../Components/Loader/Loader";
import { DeleteOutlined } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { guidAction } from "../../../Redux/Actions/actions"


export default function UserManagement() {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const guidData = useSelector(
        (state) => state.guidReducer.value
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const dispatch = useDispatch();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [prevEmail, setPrevEmail] = useState("");
    const [gid, setGid] = useState("");
    const [roleList, setRoleList] = useState([]);
    const [role, setRole] = useState("");
    const [roleName, setRoleName] = useState("");
    const [status, setStatus] = useState(true);
    const [firstNameHelper, setFirstNameHelper] = useState("");
    const [lastNameHelper, setLastNameHelper] = useState("");
    const [emailHelper, setEmailHelper] = useState("");
    const [gidHelper, setGidHelper] = useState("");
    const [userList, setUserList] = useState([]);

    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    let [isLoader, setIsLoader] = useState(false);

    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    //const handleClickOpen = () => {
    //  setOpen(true);
    //};

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };

    const handleClickOpen = () => {
        setOpenDialog(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        window.location.reload();


        //if (alertType === "" || alertType ==="success") {

        //    window.location.reload();
        //}
    };


    // const [dialogEditor, setDialogEditor] = useState(false);
    const [openDialogEditor, setOpenDialogEditor] = useState(false);

    const [openDialogDeletor, setOpenDialogDeletor] = useState(false);

    const handleCloseDialogEditor = () => {
        setOpenDialogEditor(false);
        window.location.reload();
    }

    const handleCloseDialogDeletor = () => {
        setOpenDialogDeletor(false);
        window.location.reload();


    }


    function GetDeleteButton() {
        return (
            <Tooltip title="Delete" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <DeleteOutlined fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }

    function validateEmail(email) {

        var reg = (/^\w+([\.-]?\w+)*@siemens.com$/);
        return reg.test(email);
    }

    function validateEmailG(email) {
        // var reg = (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
        var reg = (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
         
        return reg.test(email);

    }
    const [act, setAct] = useState(false);
    const handleInputChange = (event) => {
        let valid;
        switch (event.target.name) {
            case "gid":
                setGid(event.target.value);
                valid = /^[a-zA-Z][a-zA-Z0-9]*$/.test(event.target.value);
                if (!valid) {
                    setGidHelper("Cannot start with Number and Special Character");
                } else if (event.target.value.length !== 8) {
                    setGidHelper("Minimum 8 characters");
                } else {
                    setGidHelper("");
                }
                break;
            case "firstName":
                setFirstName(event.target.value);
                //valid = /^[a-zA-Z][a-zA-Z0-9]*$/.test(event.target.value);
                valid = /^[A-Za-z][A-Za-z ]*$/.test(event.target.value);
               // valid = /[^A-Za-z]/.test(event.target.value);
                // valid = /^[a-zA-Z][a-zA-Z]*$/.test(event.target.value);
               
                if (!valid) {
                    setFirstNameHelper("Cannot contain numbers & special characters");
                    //setFirstName("");
                } else if (event.target.value.length === 0) {
                    setFirstNameHelper("Cannot be left blank");
                   // setFirstName("");
                } else {
                    setFirstNameHelper("");
                }
                break;
            case "lastName":
                setLastName(event.target.value);
                valid = /^[A-Za-z]+$/.test(event.target.value);
                if (!valid) {
                    setLastNameHelper("Cannot contain number & special characters");
                } else if (event.target.value.length === 0) {
                    setLastNameHelper("Cannot be left blank");
                } else {
                    setLastNameHelper("");
                }
                break;
            case "email":
                setEmail(event.target.value);
                const validSiemensEmail = /^\w+([\.-]?\w+)*@siemens\.com$/.test(event.target.value);
                const validGenericEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value);

                if (!validGenericEmail) {
                    setEmailHelper("Invalid Email");
                } else {
                    setEmailHelper(""); // Clear error if the generic email is valid
                    if (!validSiemensEmail) {
                        // Set role value to 5 when the email is a valid Siemens email
                        setRole(5);
                        setAct(true);
                    } else {
                        // Optionally reset or clear the role if needed for non-Siemens valid emails

                        setRole(null); // or set a different default value
                        setAct(false);
                        //console.log("hi");
                    }
                }
                break;
            case "role":
                setRole(event.target.value);
                console.log("roleid", event.target.value);
                if (event.target.value === 1) {
                    setRoleName("Admin");
                }
                if (event.target.value === 5) {
                    setRoleName("ExternalUser");
                }
                break;
            case "status":
                setStatus(event.target.value);

                break;
            default:
                break;
        }
    };

    const columns = [
        { title: "First Name", field: "userFirstName" },
        { title: "Last Name", field: "userLastName" },
        { title: "Email", field: "userEmail" },
        {
            title: "GID",
            field: "gid",
        },
        { title: "Role", field: "roleName" },
        { title: "Status", field: "strIsActive" },
        //{
        //    title: "Status",
        //    field: "isActive",
        //    render: (rowData) => {
        //        return (
        //            <span>{rowData.isActive === true ? "Active" : "In-Active"}</span>
        //        );
        //    },
        //},
    ];
    function getRoleList() {
        setIsLoader(true);
        getRoles(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("rolelist", response);
                setRoleList(response);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getUser() {
        setIsLoader(true);
        getUserList(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                setUserList(response);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }


    //handleSubmit
    var formData = {
        UserId: userDetails.userId,
        UserFirstName: firstName,
        UserLastName: lastName,
        UserEmail: email,
        Gid: gid,
        UniqueId: userDetails.uniqueId,
        IsActive: status,
        CreatedBy: userDetails.userId,
        // CreatedDate: null,
        // ModifiedBy: userDetails.userId,
        //  ModifiedDate: null,
        RoleId: role,
        RoleName: roleName,
        Module: userDetails.module,



    }

    function submitData() {
        setIsLoader(true);
        handleSubmit(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {

                console.log("response", response);

                if (response.response === true) {
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "success");
                    getUser();
                    setOpenDialog(false);
                    window.location.reload();
                    // handleCloseDialog();
                    // window.location.reload();

                } else {
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "error");
                }
            })
            .catch((error) => {
                handleSnackOpen(error, "error");
                setIsLoader(false);

            });
    }
    const [userId, setUserId] = useState();
    function clickEditHardCodeMaster(rowData) {
        setOpenDialogEditor(true);
        setFirstName(rowData.userFirstName);
        setLastName(rowData.userLastName);
        setGid(rowData.gid);
        setStatus(rowData.isActive);
        setEmail(rowData.userEmail);
        setPrevEmail(rowData.userEmail);
        setRole(rowData.roleId);
        setRoleName(rowData.roleName);
        setUserId(rowData.userId);
        //handleClickOpenEditor(rowData){
        //    console.log("editor", rowData)
        //}
    }

    const handleDeleteIconClick = (rowData) => {
        console.log("deletedata", rowData);
        setOpenDialogDeletor(true);
        setFirstName(rowData.userFirstName);
        setLastName(rowData.userLastName);
        setGid(rowData.gid);
        setStatus(false);
        setEmail(rowData.userEmail);
        setRole(rowData.roleId);
        setRoleName(rowData.roleName);
        setUserId(rowData.userId);
    }

    //const handleClickOpenEditor=(rowData)=>{
    //    console.log("rowData", rowData)
    //}

    //handleUpdate


    var formDataUpdate = {
        UserId: userId,
        UserFirstName: firstName,
        UserLastName: lastName,
        UserEmail: email,
        Gid: gid,
        UniqueId: userDetails.uniqueId,
        IsActive: false,
        CreatedBy: userDetails.userId,
        // CreatedDate: null,
        ModifiedBy: userDetails.userId,
        //  ModifiedDate: null,
        RoleId: role,
        RoleName: roleName,
        Module: userDetails.module,



    }

    function editData() {
        setIsLoader(true);
        handleUpdate(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formDataUpdate, prevEmail)
            .then((response) => {

                console.log("response", response);

                if (response.response === true) {
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "success");
                    getUser();
                    setOpenDialogEditor(false);
                    window.location.reload();
                    //handleCloseDialogEditor();
                    // window.location.reload();

                }
                else {
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "error");
                }
            })
            .catch((error) => {
                handleSnackOpen(error, "error");
                setIsLoader(false);

            });
    }

    //handleDelete
    var formDataDelete = {
        UserId: userId,
        UserFirstName: firstName,
        UserLastName: lastName,
        UserEmail: email,
        Gid: gid,
        UniqueId: userDetails.uniqueId,
        IsActive: status,
        CreatedBy: userDetails.userId,
        // CreatedDate: null,
        ModifiedBy: userDetails.userId,
        //  ModifiedDate: null,
        RoleId: role,
        RoleName: roleName,
        Module: userDetails.module,



    }

    function deleteData() {
        setIsLoader(true);
        handleDelete(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formDataDelete)
            .then((response) => {

                console.log("response", response);

                if (response.response === true) {
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "success");
                    getUser();
                    setOpenDialogDeletor(false);
                    //handleCloseDialogDeletor();
                     window.location.reload();

                }
            })
            .catch((error) => {
                handleSnackOpen(error, "error");
                setIsLoader(false);

            });
    }


    useEffect(() => {
        dispatch(guidAction(""));
        getRoleList();
        getUser();
    }, []);

    return (
        <>
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12} className="d-flex jc-space-bt">
                        <Grid item xs={9} className="text-left ml-1">
                            <h2>User Management</h2>
                        </Grid>
                        <Grid item xs={3} className="text-right"></Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <MaterialTable
                            title="User List"
                            columns={columns}
                            data={userList}
                            icons={tableIconsInputScreen}
                            options={{
                                exportButton: {
                                    csv: true,
                                    pdf: false,
                                },
                                search: true,
                                exportAllData: true,
                                exportFileName: "UserList",
                                emptyRowsWhenPaging: false,
                              
                            }}
                            actions={[
                                {
                                    icon: AddIcon,
                                    isFreeAction: true,
                                    tooltip: "Add New User",
                                    onClick: () => {
                                        handleClickOpen();
                                    },
                                },
                                {
                                    icon: EditIcon,
                                    tisFreeAction: true,
                                    tooltip: "Update User",
                                    // onClick: (event, rowData) => handleClickOpenEditor(rowData),
                                    onClick: (event, rowData) => clickEditHardCodeMaster(rowData),
                                },
                                {
                                    icon: GetDeleteButton,
                                    tisFreeAction: false,
                                    onClick: (e, rowData) => {
                                        //console.log("Row Data", rowData);
                                        //deletePoEntryRow(rowData);
                                        handleDeleteIconClick(rowData);
                                    },


                                },
                            ]}
                        />
                    </Grid>
                    <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        maxWidth="lg"
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Add User</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} className="mt-05">
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        fullWidth
                                        variant="filled"
                                        value={firstName}
                                        onChange={handleInputChange}
                                        helperText={firstNameHelper}
                                        error={firstNameHelper ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name"
                                        fullWidth
                                        variant="filled"
                                        value={lastName}
                                        onChange={handleInputChange}
                                        helperText={lastNameHelper}
                                        error={lastNameHelper ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        fullWidth
                                        variant="filled"
                                        value={email}
                                        onChange={handleInputChange}
                                        helperText={emailHelper}
                                        error={emailHelper ? true : false}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Role"
                                        name="role"
                                        value={role}
                                        onChange={handleInputChange}
                                        InputLabelProps={{ shrink: role ? true : false }}
                                        fullWidth
                                        disabled={act}
                                        select
                                        variant="filled">
                                        {roleList.map((item) => (
                                            <MenuItem key={item.roleId} value={item.roleId}>
                                                {item.roleName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="gid"
                                        name="gid"
                                        disabled={role === 5}
                                        label="GID"
                                        fullWidth
                                        variant="filled"
                                        value={gid}
                                        onChange={handleInputChange}
                                        helperText={gidHelper}
                                        error={gidHelper ? true : false}
                                    />
                                </Grid>

                                {/*<Grid item xs={12} md={6}>*/}
                                {/*    <TextField*/}
                                {/*        label="Status"*/}
                                {/*        name="status"*/}
                                {/*        value={status}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        InputLabelProps={{ shrink: status ? true : false }}*/}
                                {/*        fullWidth*/}
                                {/*        select*/}
                                {/*        variant="filled">*/}
                                {/*        <MenuItem key={1} value={true}>*/}
                                {/*            Active*/}
                                {/*        </MenuItem>*/}
                                {/*        <MenuItem key={0} value={false}>*/}
                                {/*            Inactive*/}
                                {/*        </MenuItem>*/}
                                {/*    </TextField>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} className="pt-button--secondary">
                                Cancel
                            </Button>
                            <Button
                                //disabled={!firstName || !lastName || !email || !gid || !role || firstNameHelper || lastNameHelper || emailHelper || gidHelper}
                                disabled={(((!firstName || firstNameHelper || !lastName || lastNameHelper || !email || emailHelper || !gid || gidHelper || !status) && role == 1) || ((!firstName || firstNameHelper || !lastName || lastNameHelper || !email || emailHelper || !status) && role == 5) || !role)}
                                onClick={submitData} className="pt-button--primary">
                                Submit
                            </Button>

                        </DialogActions>
                    </Dialog>



                    <Dialog
                        open={openDialogEditor}
                        onClose={handleCloseDialogEditor}
                        maxWidth="lg"
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Update User</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} className="mt-05">
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        fullWidth
                                        variant="filled"
                                        value={firstName}
                                        onChange={handleInputChange}
                                        helperText={firstNameHelper}
                                        error={firstNameHelper ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name"
                                        fullWidth
                                        variant="filled"
                                        value={lastName}
                                        onChange={handleInputChange}
                                        helperText={lastNameHelper}
                                        error={lastNameHelper ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        fullWidth
                                        variant="filled"
                                        value={email}
                                        onChange={handleInputChange}
                                        helperText={emailHelper}
                                        error={emailHelper ? true : false}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Role"
                                        name="role"
                                        value={role}
                                        onChange={handleInputChange}
                                        //disabled={act }
                                        className="bg"
                                       // disabled
                                        InputLabelProps={{ shrink: role ? true : false }}
                                        fullWidth
                                        select
                                        variant="filled">
                                        {roleList.map((item) => (
                                            <MenuItem key={item.roleId} value={item.roleId}>
                                                {item.roleName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="gid"
                                        name="gid"
                                        label="GID"
                                        fullWidth
                                        variant="filled"
                                        value={gid}
                                        disabled={role === 5 || role===1}
                                        onChange={handleInputChange}
                                        helperText={gidHelper}
                                        error={gidHelper ? true : false}
                                    />
                                </Grid>
                                
                                {/*<Grid item xs={12} md={6}>*/}
                                {/*    <TextField*/}
                                {/*        label="Status"*/}
                                {/*        name="status"*/}
                                {/*        value={status}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        InputLabelProps={{ shrink: status ? true : false }}*/}
                                {/*        fullWidth*/}
                                {/*        select*/}
                                {/*        variant="filled">*/}
                                {/*        <MenuItem key={1} value={true}>*/}
                                {/*            Active*/}
                                {/*        </MenuItem>*/}
                                {/*        <MenuItem key={0} value={false}>*/}
                                {/*            Inactive*/}
                                {/*        </MenuItem>*/}
                                {/*    </TextField>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialogEditor} className="pt-button--secondary">
                                Cancel
                            </Button>
                            <Button onClick={editData}
                                //disabled={!firstName || !lastName || !email || !gid || !role || firstNameHelper || lastNameHelper || emailHelper || gidHelper} 
                                className="pt-button--primary"
                                disabled={(((!firstName || firstNameHelper || !lastName || lastNameHelper || !email || emailHelper || !gid || gidHelper || !status) && role == 1) || ((!firstName || firstNameHelper || !lastName || lastNameHelper || !email || emailHelper || !status) && role == 5) || !role)}>
                                Update
                            </Button>
                        </DialogActions>
                    </Dialog>




                    <Dialog
                        open={openDialogDeletor}
                        onClose={handleCloseDialogDeletor}
                        maxWidth="lg"
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Delete User</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} className="mt-05" style={{ display: 'flex', justifyContent: 'center' }}>

                                <h4 >are you really want to delete entry</h4>
                                {/*<Grid item xs={12} md={6}>*/}
                                {/*    <TextField*/}
                                {/*        id="firstName"*/}
                                {/*        name="firstName"*/}
                                {/*        label="First Name"*/}
                                {/*        fullWidth*/}
                                {/*        variant="filled"*/}
                                {/*        value={firstName}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        helperText={firstNameHelper}*/}
                                {/*        error={firstNameHelper ? true : false}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} md={6}>*/}
                                {/*    <TextField*/}
                                {/*        id="lastName"*/}
                                {/*        name="lastName"*/}
                                {/*        label="Last Name"*/}
                                {/*        fullWidth*/}
                                {/*        variant="filled"*/}
                                {/*        value={lastName}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        helperText={lastNameHelper}*/}
                                {/*        error={lastNameHelper ? true : false}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} md={6}>*/}
                                {/*    <TextField*/}
                                {/*        id="email"*/}
                                {/*        name="email"*/}
                                {/*        label="Email"*/}
                                {/*        fullWidth*/}
                                {/*        variant="filled"*/}
                                {/*        value={email}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        helperText={emailHelper}*/}
                                {/*        error={emailHelper ? true : false}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} md={6}>*/}
                                {/*    <TextField*/}
                                {/*        id="gid"*/}
                                {/*        name="gid"*/}
                                {/*        label="GID"*/}
                                {/*        fullWidth*/}
                                {/*        variant="filled"*/}
                                {/*        value={gid}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        helperText={gidHelper}*/}
                                {/*        error={gidHelper ? true : false}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} md={6}>*/}
                                {/*    <TextField*/}
                                {/*        label="Role"*/}
                                {/*        name="role"*/}
                                {/*        value={role}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        InputLabelProps={{ shrink: role ? true : false }}*/}
                                {/*        fullWidth*/}
                                {/*        select*/}
                                {/*        variant="filled">*/}
                                {/*        {roleList.map((item) => (*/}
                                {/*            <MenuItem key={item.roleId} value={item.roleId}>*/}
                                {/*                {item.roleName}*/}
                                {/*            </MenuItem>*/}
                                {/*        ))}*/}
                                {/*    </TextField>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} md={6}>*/}
                                {/*    <TextField*/}
                                {/*        label="Status"*/}
                                {/*        name="status"*/}
                                {/*        value={status}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        InputLabelProps={{ shrink: status ? true : false }}*/}
                                {/*        fullWidth*/}
                                {/*        select*/}
                                {/*        variant="filled">*/}
                                {/*        <MenuItem key={1} value={true}>*/}
                                {/*            Active*/}
                                {/*        </MenuItem>*/}
                                {/*        <MenuItem key={0} value={false}>*/}
                                {/*            Inactive*/}
                                {/*        </MenuItem>*/}
                                {/*    </TextField>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialogDeletor} className="pt-button--secondary">
                                Cancel
                            </Button>
                            <Button onClick={deleteData} className="pt-button--primary">
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>

                {isLoader ? <Loader loaderText="Loading...!" /> : <></>}



            </div>
            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </>
    );
}
