import {
    Button,
    DialogTitle,
    Grid,
    TextField,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
} from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    localBaseApiURL,
    tableIconsInputScreen,
} from "../../Utilities/Utility";
import "./External.scss";
import { Link, useHistory } from "react-router-dom";
import DownloadIcon from "@material-ui/icons/ArrowDownwardOutlined";
import UploadIcon from "@material-ui/icons/ArrowUpwardOutlined";
import {
    getAllCandidate,
    uploadBaseDetails,
    deleteUserDetails,
    UploadImageAPI,
    getCandidateReport,
    uploadBaseDetailsExternal,
} from "../../APIs/api_ViewDetails";
import Loading from "../../Components/Loader/Loader";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import FileUploadDialog from "../../Components/FileUploadDialog/FileUploadDialog";
import { useDispatch } from "react-redux";
import { detailsLinkAction, uploadImageRadioAction } from "../../Redux/Actions/actions";
import Template from "../../assets/template/Ecard@EAservices-Template.xlsx";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { getCandidateExternalList } from "../../Redux/API/api_admin";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
    FormControlLabel,
    Radio,
    RadioGroup
} from "@material-ui/core";


export default function FileUploadExternal() {
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const [selectedFile, setSelectedFile] = useState(null);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [fileExcelHelper, setFileExcelHelper] = useState("");
    const [inputAdditionalFileValue, setInputAdditionalFileValue] = useState(
        Date.now
    );
    const [selectedAdditionalFile, setSelectedAdditionalFile] = useState(null);
    const [fileHelper, setFileHelper] = useState("");
    const [invoiceData, setInvoiceData] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();
    let [isLoader, setIsLoader] = useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const handleDialogMessage = (text) => {
        setDialogContent(text);
        setDialogOpen(true);
    };
    let [errorData, setErrorData] = useState([]);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const uploadType = useSelector(
        (state) => state.uploadImageRadioReducer.value
    );
    const token = useSelector((state) => state.saveTokenReducer.token);
    const updateExcelHandler = (event) => {
        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (fileExtension !== 'xlsx') {
            setFileExcelHelper('Invalid file type. Please upload an XLSX file.');
        } else {
            setSelectedFile(file);
            setFileExcelHelper("");
        }
    };
    const updateAdditionalHandler = (event) => {
        const file = event.target.files[0];
        const validExtensions = [
            "image/jpeg",
            "image/png",
            "image/jpg",
            "application/zip", "application/x-zip-compressed", "application/pdf"
        ];

        if (file && validExtensions.includes(file.type)) {
            setSelectedAdditionalFile(file);
            setFileHelper("");
        } else {
            setSelectedFile(null);
            setFileHelper("Please upload a JPEG,JPG, PNG, or ZIP, PDF file.");
        }
    };
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };

    //const handleClose = (e) => {
    //    setOpen(false);
    //};
    const [selectedValue, setSelectedValue] = useState("");

    const [openDialog, setOpenDialog] = useState(false);
    const handleChange = (event) => {
        const selValue = event.target.value;
        setSelectedValue(selValue);
        dispatch(uploadImageRadioAction(selValue));
        console.log("Val", selValue);
    };
    //const handleOpen = () => {
    //    setOpen(true);
    //}

    const handleCloseDialog = () => {
        setSelectedValue("");
        setOpenDialog(false);
    };
    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        if (
            typeof dialogContent === "string" &&
            dialogContent.toLowerCase().includes("success")
        ) {
            setInputFileValue(Date.now);
            setSelectedFile(null);
        }
        setDialogOpen(false);
        setOpen(false);
        setInputFileValue(Date.now);
        setErrorData([]);
    };
    const exportErrorData = () => {
        console.log("Export");
        ExportErrorDataToExcel();
    };
    function ExportErrorDataToExcel() {
        const dataToDownload = errorData.map((row) => {
            console.log(row);
            return {
                uniqueId: row.uniqueId,
                name: row.name,
                workmanType: row.workmanType,
                remarks: row.remarks?.replace(".", ".\n "),
            };
        });
        let headers = ["Unique ID", "Name", "Workman Type", "Remarks"];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContentsCustomer(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "ErrorData");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(wb, "ExcelUploadErrors.xlsx");
    }
    function cellAdjustToContentsCustomer(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) => (c.uniqueId ? c.uniqueId?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.name ? c.name?.toString()?.length : 0))
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.remarks ? c.remarks?.toString()?.length : 0))
                ),
            },
        ];
    }

    const [fileUploadOPen, setFileUploadOPen] = useState(false);
    const [uploadedImageFile, setUploadedImageFile] = useState([]);
    const [candidateID, setCandidateID] = useState(0);
    const [guid, setGuid] = useState("");
    const handleFileUploadOpen = (rowData) => {
        dispatch(uploadImageRadioAction(""));
        setCandidateID(rowData.candidateId);
        setOpenDialog(true);
        setGuid(rowData.guid);
        setFileUploadOPen(true);
    };
    const handleFileUploadClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setFileUploadOPen(false);
    };
    const updateFileHandler = (e) => {
        const chosenFile = Array.prototype.slice.call(e.target.files);
        if (chosenFile.length > 0) {
            if (chosenFile[0].type.toString().includes("image")) {
                setUploadedImageFile(chosenFile);
                console.log("SelectedFile", e.target.files);
            } else {
                handleSnackOpen("Upload only Image files", "warning");
            }
        }
    };
    const uploadOnClick = () => {
        console.log("Selected Image", uploadedImageFile);
        if (uploadType == "") {
            handleSnackOpen("Select a type of Image.", "warning");
            return;
        } else if (uploadedImageFile.length <= 0) {
            handleSnackOpen("Select an image file.", "warning");
            return;
        }

        setIsLoader(true);
        const formData = new FormData();
        formData.append("data", uploadedImageFile[0]);
        formData.append("CandidateId", candidateID);
        formData.append("Guid", guid);
        formData.append("UploadType", uploadType);
        formData.append("UserId", userDetails.userId);

        UploadImageAPI(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                handleSnackOpen("Image Uploaded Successfully", "success");
                getCandidateData();
                dispatch(uploadImageRadioAction(""));
                setFileUploadOPen(false);
                setIsLoader(false);

                setOpenDialog(false);
               
                setSelectedValue("");
            })
            .catch((e) => {
                console.log("Error", e);
                handleSnackOpen("Error in Image Upload", "error");
                dispatch(uploadImageRadioAction(""));
                setFileUploadOPen(false);
                setIsLoader(false);
            });
    };

    const saveAsTxt = (rowData) => {
        const enggUrl = rowData.fileUrl;
        var blob = new Blob([enggUrl], {
            type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(blob, rowData.name + "-" + rowData.workmanType + ".txt");
    };

    const columns = [
        { title: "id", field: "guid", hidden: true },
        { title: "Salutation", field: "salutation" },
        { title: "Unique ID", field: "uniqueId" },
        { title: "Name", field: "name" },
        { title: "Workman Type", field: "workmanType" },
        { title: "Employer Service Partner", field: "employerServicePartner" },
        { title: "Education Stream", field: "educationStream", hidden: true },
        { title: "Experience(in years)", field: "experienceinNoofyears" },
        {
            title: "Photo Status",
            field: "photoUrl",
            render: (row) => (
                <div>
                    {row.photoUrl === "" || row.photoUrl === null
                        ? "Not Uploaded"
                        : "Uploaded"}
                </div>
            ),
        },
        {
            title: "Approval Status",
            field: "status"
        },
        {
            title: "Upload Picture/QR",
            field: "gid",
            render: (rowData) => (
                <Button disabled={rowData.status === "Approved"}>
                    <UploadIcon
                        className="checkIcon"
                        onClick={() => handleFileUploadOpen(rowData)}
                    />
                </Button>
            ),
        },
        //{
        //    title: "Upload QR",
        //    field: "gid",
        //    render: (row) => (
        //        <Button>
        //            <UploadIcon className="checkIcon" />
        //        </Button>
        //    ),
        //},
        {
            title: "Get URL",
            field: "gid",
            render: (rowData) => (
                <Button>
                    <DownloadIcon
                        className="checkIcon"
                        // onClick={() => copytoClipboardFn(rowData.fileUrl, handleSnackOpen)}
                        onClick={() => saveAsTxt(rowData)}
                    />
                </Button>
            ),
        },
        {
            title: "Download External FIle",
            field: "gid",
            render: (rowData) => (
                <a href={rowData.externalFileUrl} target="_blank">
                    < CloudDownloadIcon />
                </a>
            ),
        },
        {
            title: "Edit",
            width: "10%",
            render: (rowData) => editIconButton(rowData),
        },
    ];

    const editIconButton = (rowData) => {
        return (
            <Button
                className="pt-button--secondary"
                onClick={() => editIconClick(rowData)}>
                View Detail
            </Button>
        );
    };

    function editIconClick(rowData) {
        dispatch(detailsLinkAction("Supplier"));
        history.push("/viewDetail?id=" + rowData.guid);
    }

    function getCandidateData() {
        setIsLoader(true);
        getCandidateExternalList(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, userDetails.userId, userDetails.roleId)
            .then((response) => {
                setInvoiceData(response);
                console.log("invoice", response);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function uploadDetailsFile() {
        setIsLoader(true);
        var formdata = new FormData();
        formdata.append("file", selectedFile);
        formdata.append("data", selectedAdditionalFile);
        formdata.append("UserId", userDetails.userId);
        uploadBaseDetailsExternal(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,
            formdata)
            .then((response) => {
                if (response.response === true) {
                    getCandidateData();
                    setSelectedFile("");
                    setInputFileValue(Date.now);
                    handleDialogMessage(response.responseMsg);
                } else {
                    setIsLoader(false);
                    setSelectedFile("");
                    setInputFileValue(Date.now);
                    setErrorData(response.objResult);
                    handleDialogMessage(
                        response.responseMsgs && response.responseMsgs.length > 0
                            ? response.responseMsgs
                            : response.responseMsg
                    );
                }
            })
            .catch((error) => {
                setSelectedFile("");
                setInputFileValue(Date.now);
                setIsLoader(false);
                handleDialogMessage("Exception in upload");
                console.log("error", error);
            });
    }

    function getCandReport() {
        setIsLoader(true);
        getCandidateReport(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.byteLength <= 200) {
                        setIsLoader(false);
                        handleSnackOpen();
                        setSnackMessage("No Records are Found ");
                        setAlertType("success");
                    } else {
                        var blob = new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                        FileSaver.saveAs(blob, "CandidateDetailsReport.xlsx");
                        setIsLoader(false);
                        handleSnackOpen();
                        setSnackMessage("Report Downloaded");
                        setAlertType("success");
                    }
                } else {
                    setIsLoader(false);
                    handleSnackOpen();
                    setSnackMessage("Report download failed");
                    setAlertType("error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen();
                setSnackMessage("Error: ", error);
                setAlertType("error");
            });
    }

    const handleRowDelete = (oldTableData, resolve, reject) => {
        setIsLoader(true);
        let guid = oldTableData.guid;
        deleteUserDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, guid)
            .then((response) => {
                if (response === "Delete Successfull") {
                    setIsLoader(false);
                    handleSnackOpen("Candidate record deleted successfully", "success");
                    resolve();
                    getCandidateData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };

    useEffect(() => {
        getCandidateData();
        dispatch(detailsLinkAction(""));
    }, []);

    return (
        <>
            <div className="">
                <DialogTitle id="responsive-dialog-title">File Upload</DialogTitle>
                <Grid container spacing={2} className="">
                    <Grid container item xs={12} sm={8} md={4} spacing={3}>
                        <Grid item xs={7} sm={6} md={8}>
                            <TextField
                                label="Upload File"
                                variant="filled"
                                className="file-upload"
                                type="file"
                                name="fileUpload"
                                onChange={updateExcelHandler}
                                key={inputFileValue}
                                helperText={fileExcelHelper}
                                error={fileExcelHelper ? true : false}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2} md={4} className="paddingLeft btn-grid">
                            <Button
                                className="pt-button--secondary bulk-upload-submit-btn text-center"
                                type="button"
                                disabled={selectedFile && selectedAdditionalFile ? false : true}
                                onClick={uploadDetailsFile}>
                                Upload
                            </Button>
                        </Grid>
                        <Grid item xs={7} sm={6} md={8}>
                            <TextField
                                label="Upload Additional File"
                                variant="filled"
                                className="file-upload"
                                type="file"
                                name="fileUpload"
                                onChange={updateAdditionalHandler}
                                helperText={fileHelper}
                                error={fileHelper ? true : false}
                                key={inputAdditionalFileValue}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        className="d-flex jc-flex-start">
                        <Grid
                            item
                            xs={12}
                            md={6}
                            className="d-flex jc-space-around align-items-center">
                            <Link to={Template} target="_blank" download>
                                <Button
                                    className="pt-button--secondary bulk-upload-submit-btn "
                                    type="button">
                                    Download Template
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        className="warningTextCont align-items-center">
                        <span>
                            Note while uploading file:
                            <br />
                            1. Name, Unique ID and Workman Type are Mandatory <br />
                            2. Name should be alpha-numeric, no double space is allowed <br />
                            3. All date format should be in "mm/dd/yyyy" format<br />
                            4. Additionl upload- Only JPEG,JPG, PNG, or ZIP, PDF file allowed
                        </span>
                    </Grid>
                </Grid>

                <Grid item xs={12} className="mt-1">
                    <MaterialTable
                        title="User List"
                        columns={columns}
                        data={invoiceData}
                        icons={tableIconsInputScreen}
                        options={{
                            exportButton: { csv: true },
                            //exportCsv: (columns, data) => {
                            //    getCandReport();
                            //},
                            search: true,
                            exportAllData: true,
                            exportFileName: "StaffDetails",
                            emptyRowsWhenPaging: false,
                        }}
                        localization={{
                            toolbar: {
                                exportTitle: "Download",
                                exportAriaLabel: "Download",
                                exportName: "Download as CSV",
                            },
                        }}
                        editable={{
                            isDeletable: (rowData) => rowData.status !== "Approved",
                            onRowDelete: (oldTableData) =>
                                new Promise((resolve, reject) => {
                                    handleRowDelete(oldTableData, resolve, reject);
                                }),
                        }}
                    />
                </Grid>
                <div>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">
                            {" Data Upload Alert"}
                        </DialogTitle>
                        <DialogContent>
                            {dialogContent && typeof dialogContent === "object" ? (
                                <DialogContentText id="alert-dialog-description">
                                    <h5>
                                        {dialogContent
                                            ?.filter((x, index) => index === 0)
                                            ?.map((c) => (
                                                <>&emsp;&emsp;{c.join(" ")}</>
                                            ))}
                                    </h5>
                                    <ol className="ol-bulkUpload-Error">
                                        {dialogContent
                                            ?.filter((x, index) => index !== 0)
                                            ?.map((c) => (
                                                <li>
                                                    {c.map((x) =>
                                                        x.includes("--") && x.includes(":") ? (
                                                            x
                                                        ) : (
                                                            <ul className="ul-bulkUpload-Error">
                                                                <li>{x}</li>
                                                            </ul>
                                                        )
                                                    )}
                                                </li>
                                            ))}
                                    </ol>
                                </DialogContentText>
                            ) : (
                                <DialogContentText
                                    id="alert-dialog-description "
                                    className="dialog-center">
                                    {dialogContent?.includes("successfully.") ? (
                                        <>
                                            {dialogContent?.split("successfully.")[0] +
                                                " successfully."}
                                            {dialogContent?.split("successfully.")?.length > 0 ? (
                                                <>
                                                    <br />
                                                    {dialogContent?.split("successfully.")[1]}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ) : (
                                        dialogContent
                                    )}
                                </DialogContentText>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                className="pt-button--secondary"
                                title="Close">
                                Close
                            </Button>
                            {errorData && errorData.length > 0 ? (
                                <Button
                                    onClick={exportErrorData}
                                    className="pt-button--secondary"
                                    title={"Export   upload error data to excel"}>
                                    Export Error Data
                                </Button>
                            ) : (
                                <></>
                            )}
                        </DialogActions>
                    </Dialog>
                </div>

                <Dialog
                    width={"sm"}
                    fullWidth={"lg"}
                    maxWidth={"sm"}
                    open={openDialog}
                    onClose={handleCloseDialog}
                    disableEscapeKeyDown
                >
                    <DialogTitle id="max-width-dialog-title">Upload Image</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Grid xs={12} container>
                                <RadioGroup
                                    row
                                    aria-label="position"
                                    name="position"
                                    value={selectedValue}
                                    onChange={handleChange}
                                    defaultValue=""
                                >
                                    <FormControlLabel
                                        value="PH"
                                        control={<Radio color="primary" />}
                                        label="User Image"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="QR"
                                        control={<Radio color="primary" />}
                                        label="QR Image"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid xs={12} container>
                                <Grid item xs={12} sm={6} md={8}>
                                    <form className="file-upload-form">
                                        <TextField
                                            type="file"
                                            label="Upload File"
                                            variant="filled"
                                            className="file-upload"
                                            name="fileUpload"
                                            onChange={updateFileHandler}
                                            key={inputFileValue}
                                        />
                                    </form>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Button
                                        className="pt-button--primary bulk-upload-submit-btn"
                                        onClick={uploadOnClick}
                                        fullWidth
                                    >
                                        Upload
                                    </Button>
                                </Grid>
                            </Grid>
                            <span>Upload only image files.</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Grid xs={12} sm={6} md={6}>
                            <Button
                                onClick={handleCloseDialog}
                                className="pt-button--secondary bulk-upload-submit-btn"
                                fullWidth
                            >
                                Close
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
                {isLoader ? <Loading loaderText="Loading...." /> : <></>}
            </div>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />

            
        </>
    );
}


//<FileUploadDialog
//    open={fileUploadOPen}
//    handleClose={handleFileUploadClose}
//    updateFileHandler={updateFileHandler}
//    uploadOnClick={uploadOnClick}
///>