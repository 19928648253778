import React from "react";
import { Link } from "react-router-dom";
import "./Error404.css";

export default function Error() {
    return (
        <div className="master-layout-block main-component">
            <h2>This is Error 404. Possibly you landed on unknown URL</h2>
            <h4 className="d-flex">Please,&nbsp;<Link to="/" className="clr-white a-tag">Click Here</Link>&nbsp;to go back to homepage.</h4>
        </div>
    );
}