import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import MaterialTable from "material-table";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import {
    getCandidateExternalList,
    getSuppliers,
} from "../../Redux/API/api_admin";
import { useSelector } from "react-redux";
import { MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { uploadImageRadioAction,guidAction, detailsLinkAction } from "../../Redux/Actions/actions";
import UploadIcon from "@material-ui/icons/ArrowUpwardOutlined";
import DownloadIcon from "@material-ui/icons/ArrowDownwardOutlined";
import * as FileSaver from "file-saver";
import {
    Button,
    DialogTitle,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { handleUpdateExternalApprove, handleUpdateExternalReject } from "../../Redux/API/api_admin";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import Loader from "../../Components/Loader/Loader";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export default function ApproveReject() {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    //guidReducer
    const guidData = useSelector(
        (state) => state.guidReducer.value
    );
    console.log("guidData", guidData);
    const dispatch = useDispatch();
    const history = useHistory();
    const [supplier, setSupplier] = useState([]);
    const [supplierValue, setSupplierValue] = useState("");
    let [isLoader, setIsLoader] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const data = [
        {
            name: "Mehmet",
            surname: "Baran",
            birthYear: 1987,
            birthCity: 63,
        },
        {
            name: "Zerya Betül",
            surname: "Baran",
            birthYear: 2017,
            birthCity: 34,
        },
    ];
    //const columns = [
    //  { title: "Name", field: "name" },
    //  { title: "Surname", field: "surname" },
    //  { title: "Birth Year", field: "birthYear", type: "numeric" },
    //  {
    //    title: "Birth Place",
    //    field: "birthCity",
    //    lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    //  },
    //];
    const saveAsTxt = (rowData) => {
        const enggUrl = rowData.fileUrl;
        var blob = new Blob([enggUrl], {
            type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(blob, rowData.name + "-" + rowData.workmanType + ".txt");
    };

    const [fileUploadOPen, setFileUploadOPen] = useState(false);
    const [uploadedImageFile, setUploadedImageFile] = useState([]);
    const [candidateID, setCandidateID] = useState(0);
    const [guid, setGuid] = useState("");

    const handleFileUploadOpen = (rowData) => {
        dispatch(uploadImageRadioAction(""));
        setCandidateID(rowData.candidateId);
        setGuid(rowData.guid);
        setFileUploadOPen(true);
    };

    const editIconButton = (rowData) => {
        return (
            <Button
                className="pt-button--secondary"
                onClick={() => editIconClick(rowData)}>
                View Detail
            </Button>
        );
    };
    const [open, setOpen] = useState(false);
    

    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    function editIconClick(rowData) {
        dispatch(detailsLinkAction("ApproveReject"));
        history.push("/viewDetail?id=" + rowData.guid);
    }
    const [rowValue, setRowValue] = useState("");
    const columns = [
        { title: "id", field: "guid", hidden: true },
        { title: "Salutation", field: "salutation" },
        { title: "Unique ID", field: "uniqueId" },
        { title: "Name", field: "name" },
        { title: "Workman Type", field: "workmanType" },
        { title: "Employer Service Partner", field: "employerServicePartner" },
        { title: "Education Stream", field: "educationStream", hidden: true },
        { title: "Experience(in years)", field: "experienceinNoofyears" },
        //{
        //    title: "Photo Status",
        //    field: "photoUrl",
        //    render: (row) => (
        //        <div>
        //            {row.photoUrl === "" || row.photoUrl === null
        //                ? "Not Uploaded"
        //                : "Uploaded"}
        //        </div>
        //    ),
        //},
        //{
        //    title: "Upload Picture/QR",
        //    field: "gid",
        //    render: (rowData) => (
        //        <Button>
        //            <UploadIcon
        //                className="checkIcon"
        //                onClick={() => handleFileUploadOpen(rowData)}
        //            />
        //        </Button>
        //    ),
        //},
        //{
        //    title: "Upload QR",
        //    field: "gid",
        //    render: (row) => (
        //        <Button>
        //            <UploadIcon className="checkIcon" />
        //        </Button>
        //    ),
        //},
        {
            title: "Get URL",
            field: "gid",
            render: (rowData) => (
                <Button>
                    <DownloadIcon
                        className="checkIcon"
                        // onClick={() => copytoClipboardFn(rowData.fileUrl, handleSnackOpen)}
                        onClick={() => saveAsTxt(rowData)}
                    />
                </Button>
            ),
        },
        {
            title: "Download External FIle",
            field: "gid",
            render: (rowData) => (
                <a href={rowData.externalFileUrl} target="_blank">
                    < CloudDownloadIcon />
                </a>
            ),
        },
        //{
        //    title: "Edit",
        //    width: "10%",
        //    render: (rowData) => editIconButton(rowData),
        //},
        {
            title: "Edit",
            width: "10%",
            render: (rowData) =>  editIconButton(rowData) 
        },
    ];
   // console.log("rowValue", rowValue);

    function handleInputChange(event) {
        if (guidData) {
            dispatch(guidAction(""));
        }
        setRowValue(event.target.value);
        setSupplierValue(event.target.value);
        getCandidateData(event.target.value);
        dispatch(guidAction(event.target.value));

    }


   // getCandidateData(rowData.);
    function getSuppliersList() {
        getSuppliers(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                setRoleList(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function getCandidateData(id) {
        setIsLoader(true);
        getCandidateExternalList(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, id, userDetails.roleId)
            .then((response) => {
                console.log("invoice", response);
                setInvoiceData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function approveUsers(data) {
        // const extractedIds = data[0].candidateId;
        const extractedIds = data.map((obj) => obj.candidateId);
        const activeStatus = data[0].status;
        let formData = {


            CandidateId: extractedIds,
            Status: activeStatus,
            ModifiedBy: userDetails.userId,
           // ModifiedDate: "",



        }
        handleUpdateExternalApprove(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                handleSnackOpen(response, "success");
                setIsLoader(false);
                getCandidateData(supplierValue);
                if (guidData) {
                    getCandidateData(guidData);
                }
                //if (response.response === true) {
                //    setIsLoader(false);
                //    // handleSnackOpen(response.responseMsg, "success");


                //}
            })
            .catch((error) => {
                // handleSnackOpen(error, "error");
                setIsLoader(false);

            });
    }
    //function rejectUsers(data) {
    //    const extractedIds = data.map((obj) => obj.name);
    //    console.log(extractedIds);
    //}
    //handleUpdateExternalApprove

    function rejectUsers(data) {
        console.log("rejectdata", data);
        // const extractedIds = data[0].candidateId;
        const extractedIds = data.map((obj) => obj.candidateId);
        const activeStatus = data[0].status;
        console.log(extractedIds);
        let formData = {


            CandidateId: extractedIds,
            Status: activeStatus,
            ModifiedBy: userDetails.userId,
            // ModifiedDate: "",



        }
        handleUpdateExternalReject(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {

                console.log("response", response);

                handleSnackOpen(response, "success");
                setIsLoader(false);
                getCandidateData(supplierValue);
                if (guidData) {
                    getCandidateData(guidData);
                }
                //if (response.response === true) {
                //    setIsLoader(false);
                //    // handleSnackOpen(response.responseMsg, "success");


                //}
            })
            .catch((error) => {
                // handleSnackOpen(error, "error");
                setIsLoader(false);

            });
    }

    useEffect(() => {
        dispatch(detailsLinkAction(""));
        getSuppliersList();
        // getCandidateData(guidData);
        if (guidData) {
            getCandidateData(guidData);
        }
    }, [guidData]);

    return (
        <>
            <Grid container spacing={2}>
                 <Grid item xs={12} className="d-flex jc-center">
        <h3>User Approve/Reject</h3>
      </Grid> 
                <Grid item xs={12} md={4}>
                    <TextField
                        label="External Supplier"
                        name="externalSupplier"
                        value={guidData? guidData:supplierValue}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: supplierValue || guidData ? true : false }}
                        fullWidth
                        select
                        variant="filled">
                        {roleList.map((item) => (
                            <MenuItem key={item.userId} value={item.userId}>
                                {item.userEmail}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    {/*<MaterialTable*/}
                    {/*  title="Approve/Reject Candidates"*/}
                    {/*  columns={columns}*/}
                    {/*  data={data}*/}
                    {/*  icons={tableIconsInputScreen}*/}
                    {/*  options={{*/}
                    {/*    selection: true,*/}
                    {/*  }}*/}
                    {/*  actions={[*/}
                    {/*    {*/}
                    {/*      tooltip: "Approve All Selected Users",*/}
                    {/*      icon: SaveIcon,*/}
                    {/*      onClick: (evt, data) =>*/}
                    {/*        alert("You want to Approve " + data.length + " rows"),*/}
                    {/*    },*/}
                    {/*    {*/}
                    {/*      tooltip: "Reject All Selected Users",*/}
                    {/*      icon: ClearIcon,*/}
                    {/*      onClick: (evt, data) => approveUsers(data),*/}
                    {/*    },*/}
                    {/*  ]}*/}
                    {/*      />*/}
                    <Grid item xs={12} className="mt-1">
                        <MaterialTable
                            title="User List"
                            columns={columns}
                            data={invoiceData}
                            icons={tableIconsInputScreen}
                            options={{
                                selection: true,
                                exportButton: {
                                    csv: true,
                                    pdf: false,
                                },
                            }}
                            localization={{
                                toolbar: {
                                    exportTitle: "Download",
                                    exportAriaLabel: "Download",
                                    exportName: "Download as CSV",
                                },
                            }}
                            actions={[
                                {
                                    tooltip: "Approve All Selected Users",
                                    icon: CheckIcon,
                                    onClick: (evt, data) =>
                                        approveUsers(data)
                                    // alert("You want to Approve " + data.length + " rows"),
                                },
                                {
                                    tooltip: "Reject All Selected Users",
                                    icon: ClearIcon,
                                    onClick: (evt, data) => rejectUsers(data),
                                },
                            ]}

                        />
                    </Grid>
                </Grid>
                {isLoader ? <Loader loaderText="Loading...!" /> : <></>}
            </Grid>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />

        </>
    );
}
