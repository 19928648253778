import axios from "axios";
import React, { useState, useEffect } from "react";
import { baseApiURL } from "../QRCalibration/APIs/api_QRCalib";
import Loader from "../../Components/Loader/Loader";
import FileSaver from "file-saver";

// Required URL format after QR scan
// https://localhost:44313/QRScanCalibrationCertificate?SrfNo=N2301001
// https://smartqruat.si-in.siemens.cloud/QRScanCalibrationCertificate?SrfNo=N2301001

export default function GetCalibCertDetails() {

    const [isLoader, setIsLoader] = useState(false);
    function getSRFParameter() {
        const searchPageURL = window.location.search.substring(1);
        const startIndex = searchPageURL.indexOf("=") + 1;
        const endIndex = searchPageURL.length;
        const srfNoVar = searchPageURL.slice(startIndex, endIndex);
        return srfNoVar;
    }
    var SrfNo = getSRFParameter();

    const displayCalibCert = () => {
        setIsLoader(true);
        const options = {
            responseType: "arraybuffer",
        };
        axios.get(baseApiURL + "/api/Candidate/QRScanCalibrationCertificateFTP?TimeStampForQr=" + SrfNo, options)
            .then((response) => {
                setIsLoader(false);
                if (response.status === 200) {
                    if (response.data) {
                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });
                        FileSaver.saveAs(blob, "CalibrationCertificate.pdf");
                        setIsLoader(false);
                        alert("Certificate with QR downloaded.");
                    } else {
                        setIsLoader(false);
                        alert("No Records are Found");
                    }
                }
                else {
                    console.log("PDF File Not Found");
                    alert("Certificate Not Found !!");
                }
            })
            .catch((err) => {
                setIsLoader(false);
                alert("Error !!");
            });

    }

    useEffect(() => {
        displayCalibCert();
    }, [SrfNo]);

    return (
        <>
            {isLoader ? <Loader loaderText="Fetching Certificate ...." /> : <></>}
        </>
    );
}