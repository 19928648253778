import {
    DialogTitle,
    Grid,
    makeStyles,
    TextField,
    Avatar,
    Tooltip,
    TextareaAutosize,
    MenuItem,
    Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
    getCandidateData,
    updateCandidateData,
} from "../../APIs/api_ViewDetails";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../Components/Loader/Loader";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import * as FileSaver from "file-saver";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: `center`,
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
    details: {
        alignSelf: `center`,
    },
    atag: {
        alignSelf: `center`,
        fontStyle: `italic`,
        textDecoration: `underline`,
        display: `flex`,
        justifyContent: `center`,
    },
    ablock: {
        color: `#099 !important`,
    },
    secondaryHeader: {
        margin: 0,
    },
    profilepic: {
        border: `2px double #00BEDC`,
        boxShadow: `0px 2px 10px 5px #00BEDC`,
    },
    profilepicTech: {
        border: `2px double #099`,
        boxShadow: `0px 2px 10px 5px #00FFB9`,
    },
}));

export default function ViewDetails() {
    const classes = useStyles();
    const history = useHistory();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const guidData = useSelector(
        (state) => state.guidReducer.value
    );
    const historyLink = useSelector(
        (state) => state.saveDetailsLinkReducer.value
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [pageHeader, setPageHeader] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    let [isLoader, setIsLoader] = useState(false);
    const [salutation, setSalutation] = useState("");
    const [name, setName] = useState("");
    const [employerServicePartner, setEmployerServicePartner] = useState("");
    const [empUniqueID, setEmpUniqueID] = useState("");
    const [educationalQualification, setEducationalQualification] = useState("");
    const [educationalStream, setEducationalStream] = useState("");
    const [experience, setExperience] = useState("");
    const [aisValidity, setAisValidity] = useState(null);
    const [gisValidity, setGisValidity] = useState(null);
    const [rmuValidity, setRmuValidity] = useState(null);
    const [cssValidity, setCssValidity] = useState(null);
    const [lvdsValidity, setLvdsValidity] = useState(null);
    const [protectionValidity, setProtectionValidity] = useState(null);
    const [statusD, setStatusD] = useState("");
    const [ehsValidity, setEhsValidity] = useState(null);
    const [trainingUndergone, setTrainingUndergone] = useState("");
    const [protectionCompetenceLevel, setProtectionCompetenceLevel] =
        useState("");
    const [fileURL, setFileURL] = useState("");
    const [automationCompetenceLevel, setAutomationCompetenceLevel] =
        useState("");
    const [automationValidity, setAutomationValidity] = useState(null);
    const [generatedQRCode, setGeneratedQRCode] = useState("");
    //helpertexts
    const [nameHelper, setNameHelper] = useState("");
    const [employerServicePartnerHelper, setEmployerServicePartnerHelper] =
        useState("");
    const [educationalQualificationHelper, setEducationalQualificationHelper] =
        useState("");
    const [educationalStreamHelper, setEducationalStreamHelper] = useState("");
    const [experienceHelper, setExperienceHelper] = useState("");
    const token = useSelector((state) => state.saveTokenReducer.token);
    //snackbar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };

    const [imageContent, setImageContent] = useState(null);

    const handleChangeSalutation = (event) => {
        setSalutation(event.target.value);
    };
    const handleInputChange = (event) => {
        let valid;
        switch (event.target.id) {
            case "name":
                setName(event.target.value);
                valid = /^[a-zA-Z ]*$/.test(event.target.value);
                if (!valid) {
                    setNameHelper("Cannot contain Special Character and Numbers");
                } else if (event.target.value === "") {
                    setNameHelper("Cannot be left blank");
                } else {
                    setNameHelper("");
                }
                break;
            case "employerServicePartner":
                setEmployerServicePartner(event.target.value);
                if (event.target.value === "") {
                    setEmployerServicePartnerHelper("Cannot be left blank");
                } else {
                    setEmployerServicePartnerHelper("");
                }
                break;
            case "educationalQualification":
                setEducationalQualification(event.target.value);
                if (event.target.value === "") {
                    setEducationalQualificationHelper("Cannot be left blank");
                } else {
                    setEducationalQualificationHelper("");
                }
                break;
            case "educationalStream":
                setEducationalStream(event.target.value);
                if (event.target.value === "") {
                    setEducationalStreamHelper("Cannot be left blank");
                } else {
                    setEducationalStreamHelper("");
                }
                break;
            case "experience":
                valid = /^[1-9 ]/.test(event.target.value);
                setExperience(event.target.value);
                if (event.target.value.length === 0) {
                    setExperienceHelper("Cannot be left blank");
                } else if (!valid) {
                    setExperienceHelper(
                        "Cannot contain 0, Special Character and Alphabets"
                    );
                } else {
                    setExperienceHelper("");
                }
                break;
            case "automationCompetenceLevel":
                setAutomationCompetenceLevel(event.target.value);
                break;
            case "protectionCompetenceLevel":
                setProtectionCompetenceLevel(event.target.value);
                break;
            default:
                break;
        }
    };
    const [res, setRes] = useState("");

    const goBack = () => {
        if (historyLink === "Admin") {
            
            window.location.href = "/fileUpload";
        } else if (historyLink === "Supplier") {
            window.location.href = "/fileUploadExternal";
        } else {
            window.location.href = "/ApproveReject";
        }
       
    }

    const handleChangeOtherTrainings = (event) => {
        setTrainingUndergone(event.target.value);
    };

    const handleAisValidity = (date) => {
        setAisValidity(date);
    };
    const handleGisValidity = (date) => {
        setGisValidity(date);
    };
    const handleRmuValidity = (date) => {
        setRmuValidity(date);
    };
    const handleCssValidity = (date) => {
        setCssValidity(date);
    };
    const handleLvdsValidity = (date) => {
        setLvdsValidity(date);
    };
    const handleProtectionValidity = (date) => {
        setProtectionValidity(date);
    };
    const handleEhsValidity = (date) => {
        setEhsValidity(date);
    };
    const handleAutomationValidity = (date) => {
        setAutomationValidity(date);
    };

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("=");
            var value = "";
            for (var j = 0; j < sParameterName.length; j++) {
                if (sParameterName[j] == sParam) {
                    for (var k = j + 1; k < sParameterName.length; k++) {
                        value = value + sParameterName[k];
                    }
                    break;
                }
                //return sParameterName[1];
            }
            return value;
        }
    }
    var encryptedId = getURLParameter("id");
   
    function getMasterData() {
        setIsLoader(true);
        getCandidateData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, encryptedId)
            .then((response) => {
                setRes(response.guid)
                setImageUrl(response.photoUrl);
                setImageContent(response.photoUrlShow);
                setPageHeader(response.workmanType);
                setSalutation(response.salutation.toLowerCase());
                setName(response.name);
                setEmployerServicePartner(response.employerServicePartner);
                setEmpUniqueID(response.uniqueId);
                setEducationalQualification(response.educationalQualification);
                setEducationalStream(response.educationStream);
                setExperience(response.experienceinNoofyears);
                setAisValidity(response.mvAisValidityTill);
                setGisValidity(response.mvGisValidityTill);
                setRmuValidity(response.rmuValidityTill);
                setCssValidity(response.cssValidityTill);
                setLvdsValidity(response.lvdsValidityTill);
                setProtectionValidity(response.protectionValidityTill);
                setEhsValidity(response.ehsSitrustValidityTill);
                setTrainingUndergone(response.otherTrainings);
                setProtectionCompetenceLevel(response.protectionCompetencyLevelType);
                setFileURL(response.fileUrl);
                setAutomationCompetenceLevel(response.automationCompetenceLevel);
                setAutomationValidity(response.automationValidityTill);
                setGeneratedQRCode(response.qrCode);
                setStatusD(response.status);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    var formdata = {
        guid: encryptedId,
        automationCompetenceLevel: automationCompetenceLevel,
        automationValidityTill: automationValidity,
        cssValidityTill: cssValidity,
        educationStream: educationalStream,
        educationalQualification: educationalQualification,
        ehsSitrustValidityTill: ehsValidity,
        employerServicePartner: employerServicePartner,
        experienceinNoofyears: experience,
        lvdsValidityTill: lvdsValidity,
        mvAisValidityTill: aisValidity,
        mvGisValidityTill: gisValidity,
        name: name,
        otherTrainings: trainingUndergone,
        protectionCompetencyLevelType: protectionCompetenceLevel,
        protectionValidityTill: protectionValidity,
        rmuValidityTill: rmuValidity,
        salutation: salutation,
        modifiedBy: userDetails.userId,
    };

    function handleSubmit() {
        setIsLoader(true);
        updateCandidateData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formdata, encryptedId)
            .then((response) => {
                if (response.result == "Update Successfull") {
                    handleSnackOpen("Candidate data updated successfully.", "success");
                    setIsLoader(false);
                    getMasterData();
                } else {
                    setIsLoader(false);
                    handleSnackOpen(response.result, "error");
                }
            })
            .catch((error) => {
                alert("error", error);
                setIsLoader(false);
                console.log("error", error);
            });
    }

    useEffect(() => {
        console.log(historyLink)
        if (encryptedId) {
            getMasterData();
        }
    }, [encryptedId]);

    const saveAsTxt = (enggUrl) => {
        var blob = new Blob([enggUrl], {
            type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(blob, name + "-" + pageHeader + ".txt");
    };

    return (
        <>
            <DialogTitle id="responsive-dialog-title">
                {pageHeader} Details
            </DialogTitle>
            <Grid container spacing={2} className="mt-1">
                <Grid
                    container
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    spacing={2}
                    className={classes.root}
                >
                    <div
                        className={
                            pageHeader == "Engineer"
                                ? classes.profilepic
                                : pageHeader == "Technician"
                                    ? classes.profilepicTech
                                    : ""
                        }
                    >
                        <Avatar
                            variant="rounded"
                            alt="Remy Sharp"
                            src={imageUrl}
                            className={classes.large}
                        />

                        {/*<img*/}
                        {/*  src={`${imageContent}`}*/}
                        {/*  alt="Profile"*/}
                        {/*  height="140"*/}
                        {/*  width="140"*/}
                        {/*/>*/}
                    </div>
                </Grid>
                <Grid container item xs={12} sm={8} md={9} spacing={2}>
                    <Grid item xs={12} sm={4} className={classes.details}>
                        <TextField
                            className=""
                            fullWidth
                            select
                            name="salutation"
                            label="Salutation"
                            variant="filled"
                            value={salutation}
                            onChange={handleChangeSalutation}
                            id="salutation"
                        >
                            <MenuItem value={"mr"}>Mr</MenuItem>
                            <MenuItem value={"miss"}>Miss</MenuItem>
                            <MenuItem value={"mrs"}>Mrs</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.details}>
                        <TextField
                            className=""
                            fullWidth
                            name="name"
                            label="Name"
                            variant="filled"
                            value={name}
                            error={nameHelper.length !== 0}
                            helperText={nameHelper}
                            onChange={handleInputChange}
                            id="name"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.details}>
                        <TextField
                            className=""
                            fullWidth
                            name="employerServicePartner"
                            label="Employer Service Partner"
                            variant="filled"
                            value={employerServicePartner}
                            onChange={handleInputChange}
                            error={employerServicePartnerHelper.length !== 0}
                            helperText={employerServicePartnerHelper}
                            id="employerServicePartner"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.details}>
                        <TextField
                            className=""
                            fullWidth
                            disabled
                            name="uniqueID"
                            label="Unique ID"
                            variant="filled"
                            value={empUniqueID}
                            // onChange={handleInputChange}
                            id="uniqueID"
                        />
                    </Grid>
                    <Grid item xs={12} md={9} sm={8} className={classes.atag}>
                        <Tooltip title="Click to Download">
                            <span
                                className="link"
                                onClick={() => saveAsTxt(fileURL)}
                            >
                                {fileURL}
                            </span>
                        </Tooltip>
                    </Grid>

                    {generatedQRCode !== null &&
                        generatedQRCode !== undefined &&
                        generatedQRCode !== "" ? (
                            <Grid item xs={12} sm={4} md={3} className=" d-flex jc-flex-end">
                            <Button
                                className="pt-button--primary bulk-upload-submit-btn"
                                type="button"
                                onClick={() => FileSaver(generatedQRCode, name + "-qr.jpg")}
                            >
                                Download QR
                            </Button>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
            <hr />
            <h4>Qualification Details</h4>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="educationalQualification"
                        label="Educational Qualification"
                        variant="filled"
                        value={educationalQualification}
                        onChange={handleInputChange}
                        error={educationalQualificationHelper.length !== 0}
                        helperText={educationalQualificationHelper}
                        id="educationalQualification"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="educationalStream"
                        label="Educational Stream"
                        variant="filled"
                        value={educationalStream}
                        onChange={handleInputChange}
                        error={educationalStreamHelper.length !== 0}
                        helperText={educationalStreamHelper}
                        id="educationalStream"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="experience"
                        label="Experience (in years)"
                        variant="filled"
                        value={experience}
                        onChange={handleInputChange}
                        error={experienceHelper.length !== 0}
                        helperText={experienceHelper}
                        id="experience"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}></Grid>
                <hr />
                <Grid item xs={12}>
                    <h4 className={classes.secondaryHeader}>Training Status</h4>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="date-picker-dialog"
                            label="MV AIS Validity till"
                            format="dd/MM/yyyy"
                            placeholder="MV AIS Validity till"
                            fullWidth
                            value={aisValidity}
                            onChange={handleAisValidity}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="date-picker-dialog"
                            label="MV GIS Validity till"
                            format="dd/MM/yyyy"
                            placeholder="MV GIS Validity till"
                            fullWidth
                            value={gisValidity}
                            onChange={handleGisValidity}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="date-picker-dialog"
                            label="RMU validity till"
                            format="dd/MM/yyyy"
                            placeholder="RMU validity till"
                            fullWidth
                            value={rmuValidity}
                            onChange={handleRmuValidity}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="date-picker-dialog"
                            label="CSS validity till"
                            format="dd/MM/yyyy"
                            placeholder="CSS validity till"
                            fullWidth
                            value={cssValidity}
                            onChange={handleCssValidity}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="date-picker-dialog"
                            label="LVDS validity till"
                            format="dd/MM/yyyy"
                            placeholder="LVDS validity till"
                            fullWidth
                            value={lvdsValidity}
                            onChange={handleLvdsValidity}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="date-picker-dialog"
                            label="EHS - SITRUST Validity till"
                            format="dd/MM/yyyy"
                            placeholder="EHS - SITRUST Validity till"
                            fullWidth
                            value={ehsValidity}
                            onChange={handleEhsValidity}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="protectionCompetenceLevel"
                        label="Protection Competence Level Type"
                        variant="filled"
                        value={protectionCompetenceLevel}
                        id="protectionCompetenceLevel"
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="date-picker-dialog"
                            label="Protection validity till"
                            format="dd/MM/yyyy"
                            placeholder="Protection validity till"
                            fullWidth
                            value={protectionValidity}
                            onChange={handleProtectionValidity}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="automationCompetenceLevel"
                        label="Automation Competence Level Type"
                        variant="filled"
                        value={automationCompetenceLevel}
                        id="automationCompetenceLevel"
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="date-picker-dialog"
                            label="Automation validity till"
                            format="dd/MM/yyyy"
                            placeholder="Automation validity till"
                            fullWidth
                            value={automationValidity}
                            onChange={handleAutomationValidity}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="Other Trainings List"
                        className="other-trainings"
                        value={trainingUndergone}
                        onChange={handleChangeOtherTrainings}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} className="d-flex jc-center mt-1">
                <Grid item xs={6} sm={4} md={2} lg={1}>
                    <Button
                        className="pt-button--secondary bulk-upload-submit-btn"
                        type="button"
                        fullWidth
                        // onClick={() => history.goBack()}
                        onClick={goBack}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={2} lg={1}>
                    <Button
                        className="pt-button--primary bulk-upload-submit-btn"
                        type="button"
                        fullWidth
                        onClick={handleSubmit}
                        disabled={
                            nameHelper ||
                            employerServicePartnerHelper ||
                            experienceHelper ||
                            educationalQualificationHelper ||
                            educationalStreamHelper
                        }
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>

            {isLoader ? <Loading loaderText="Loading...." /> : <></>}

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </>
    );
}
