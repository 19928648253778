import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import Cognisphere from "../../../assets/images/Cognisphere.png";
import "./Home.scss";
import { QRMenu } from "../../../Utilities/Utility";
import { useDispatch } from "react-redux";
import { guidAction, storeMenuItemsAction } from "../../../Redux/Actions/actions"
import {
    getAllRoles
} from "../../../APIs/api_Login";

export default function QRHome() {
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const dispatch = useDispatch();
    const [routes, setRoutes] = useState([]);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const landingMenuItems = useSelector(state => state.storeLandingMenuItemsReducer.menuItems) || [];
   
    function getMenus() {
        
        getAllRoles(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId
        )
            .then((response) => {
                if (response) {
                    console.log('land', response)
                    //setRoutes(response);
                    dispatch(storeMenuItemsAction(response));
                } else {
                    // setRoutes([]);
                    dispatch(storeMenuItemsAction([]));
                }
            })
            .catch((error) => {
                console.log("Error: " + error, "error");
            });
    }

    //useEffect(() => {
    //   /* getMenus();*/
    //    dispatch(guidAction(""));
    //}, []);

    //useEffect(() => {
    //    if (userDetails && userDetails.userId > 0 && siteToken.token !== "") {

    //        getMenus();
    //    }
    //    // dispatch(isForgotPasswordAction(false));
    //}, [userDetails, siteToken]);
    useEffect(() => {
        if (userDetails?.userId > 0 && siteToken !== "" && landingMenuItems?.length <= 0) {
            getMenus();
        }
        dispatch(guidAction(""));
    }, [userDetails]);

    return (
        <div className="main-component">
            <div className="potts-master-block">
                <main className="main-component">
                    <img
                        src={Cognisphere}
                        alt="Cognisphere"
                        className="landing-cognisphere"
                    />
                    <div className="bg-landing-page">
                        <Container maxWidth="lg">
                            <Grid container className="homepage-container">
                                <Grid item xs={12} sm={12} md={12} lg={5} className="z-9">
                                    <div className="logged-in-user">
                                        <h1 className="logged-in-user-heading">
                                            <span className="logged-in-greetings">Hello,</span>
                                            <span className="logged-in-user-name">
                                                {userDetails.userFirstName}
                                            </span>
                                        </h1>
                                        <p className="logged-in-project-desc">
                                            Welcome to QR Code on Calibration Certificate Tool
                                        </p>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={7}
                                    className="landing-page-menu"
                                >
                                    <Grid container spacing={2}>
                                        {landingMenuItems.map((item, index) => (
                                            <Grid item xs={12} sm={6} md={6} key={index}>
                                                <Link to={item.menuLink} key={index}>
                                                    <div className="menu-item-container">
                                                        <div className="menu-item-text-block">
                                                            <span className="align-center ml-1">
                                                                {item.menuName}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </main>
            </div>
        </div>
    );
}
