import { localBaseApiURL } from "../Utilities/Utility";
import axios from "axios";

export function getAllCandidate(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
    };
    return fetch(localBaseApiURL + "candidate/GetAllCandidateDetails?EncuserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
            console.log(data);
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function uploadBaseDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        body: formData,
    };
    return fetch(localBaseApiURL + "candidate/SaveUploadDetails?EncuserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function uploadBaseDetailsExternal(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
  const options = {
    method: "post",
    headers: {

        Authorization: "Bearer " + siteToken.token,
        UserEmail: userEmail,
        Gid: gid,
        Roleid: roleId,
        AzureToken: azureToken,
        RoleName: roleName,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
    return fetch(localBaseApiURL + "candidate/SaveUploadDetailsExternal?EncuserId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function deleteUserDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        //body: JSON.stringify(data),
    };
    return fetch(
        localBaseApiURL + "candidate/DeleteCandidateDetails?EncuserId=" + encodeURIComponent(userId) +"&Guid=" + data,
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getCandidateData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, id) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
    };
    return fetch(
        localBaseApiURL + "candidate/GetCandidateDetails?EncuserId=" + encodeURIComponent(userId) + "&Guid=" + id,
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
            console.log(data);
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateCandidateData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData, id) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData),
    };
    return fetch(
        localBaseApiURL + "candidate/UpdateCandidateDetails?EncuserId=" + encodeURIComponent(userId) + "&Guid=" + id,
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function UploadImageAPI(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: formData,
    };

    return fetch(localBaseApiURL + "candidate/UploadSnapshot?EncuserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

//external data
export function getCandidateDetails(siteToken, id) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    return fetch(localBaseApiURL + "candidate/GetDetails?UniqueID=" + id, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
            console.log(data);
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getCandidateReport(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
    };
    return axios
        .get(
            localBaseApiURL + "candidate/GetCandidateReport?EncuserId=" + encodeURIComponent(userId),
            options
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
   
}
//QR Calibration
export function getAllCalibrationCandidateDetails(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
    };
    return fetch(localBaseApiURL + "candidate/GetAllCalibrationCandidateDetails?EncuserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
            console.log(data);
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function uploadBaseCalibrationDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        body: formData,
    };
    return fetch(localBaseApiURL + "candidate/UploadCalibrationDetails?EncuserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

