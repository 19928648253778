import { localBaseApiURL } from "../../Utilities/Utility";

export async function getRoles(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
    };
    try {
        const response = await fetch(
            localBaseApiURL + "candidate/GetRoleMaster?EncuserId=" + encodeURIComponent(userId),
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}
export async function getSuppliers(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
    };
    try {
        const response = await fetch(
            localBaseApiURL + "candidate/GetExternalUserDetails?EncuserId=" + encodeURIComponent(userId),
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}


export async function getUserList(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    try {
        const response = await fetch(
            localBaseApiURL + "candidate/GetUserDetails?EncuserId=" + encodeURIComponent(userId),
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}



//export function handleSubmit(formData, siteToken) {
//    const options = {
//        method: "post",
//        //headers: {
//        //    Authorization: "Bearer " + siteToken,
//        //},
//        headers: {
//            Authorization: "Bearer " + siteToken,
//            Accept: "application/json, text/plain, */*",
//            "Content-Type": "application/json",
//        },
//        body: JSON.stringify(formData),
//    }
//       // processData: false,
//      // mimeType: "multipart/form-data",
//       // contentType: false,
//        //body: formData,


//    return fetch(localBaseApiURL + "candidate/PostUserDetails", options)
//        .then((response) => response.json())
//        .then((data) => {
//            return data.result;
//        })
//        .catch((error) => {
//            console.log("Error : ", error);
//        });
//}

//export function handleSubmit(formData, siteToken) {
//    const options = {
//        method: "post",
//        headers: {
//            Accept: "application/json, text/plain, */*",
//            "Content-Type": "application/json",
//            Authorization: "Bearer " + siteToken,
//        },
//        body: JSON.stringify(formData),
//    };
//    return fetch(
//        localBaseApiURL + "candidate/PostUserDetails",
//        options
//    )
//        .then((response) => response.json())
//        .then((data) => {
//            return data;
//        })
//        .catch((error) => {
//            console.log("Error : ", error);
//        });
//}

export function handleSubmit(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    formData
) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,

        },
        body: JSON.stringify(formData),
    };
    return fetch(
        localBaseApiURL +
        "candidate/PostUserDetails?EncuserId=" + encodeURIComponent(userId),
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

//put-->PutUserDetails

export function handleUpdate(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    formData,
    email
) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
            email: email,

        },
        body: JSON.stringify(formData),
    };
    return fetch(
        localBaseApiURL +
        "candidate/PutUserDetails?EncuserId=" + encodeURIComponent(userId),
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function handleDelete(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    formData

) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData),
    };
    return fetch(
        localBaseApiURL +
        "candidate/DeleteUserDetails?EncuserId=" + encodeURIComponent(userId),
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}


export async function getCandidateExternalList(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    CreatedId,
    Roleid) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
    };
    try {
        const response = await fetch(
            localBaseApiURL +
            "candidate/GetAllCandidateDetailsExternal?EncuserId=" + encodeURIComponent(userId) + "&CreatedBy=" +
            CreatedId + "&Roleid=" + Roleid,
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}

//UpdateCandidateDetailsApprovedExternal

export function handleUpdateExternalApprove(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    formData

) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,

        },
        body: JSON.stringify(formData),
    };
    return fetch(
        localBaseApiURL +
        "candidate/UpdateCandidateDetailsApprovedExternal?EncuserId=" + encodeURIComponent(userId),
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//UpdateCandidateDetailsRejectedExternal

export function handleUpdateExternalReject(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    formData

) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,

        },
        body: JSON.stringify(formData),
    };
    return fetch(
        localBaseApiURL +
        "candidate/UpdateCandidateDetailsRejectedExternal?EncuserId=" + encodeURIComponent(userId),
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}


