import axios from "axios";

export const baseApiURL = window.location.origin;

export function uploadPDFCertificate(siteToken, azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    fileData) {
    const options = {
        method: "post",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        body: fileData,
    };
    return fetch(baseApiURL + "/api/Candidate/UploadCalibrationCertificateWithNoQr?EncuserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            return (error);
        });
}

export function downloadPDFCertificate(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
     data) {
    const options = {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
            
        },
    };
    return axios
        .post(
            baseApiURL + "/api/Candidate/DownloadPdfWithQR?EncuserId=" + encodeURIComponent(userId),
            data,
            options
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });

}

export function attachQRInPDF(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    fileData) {
    const options = {
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,


                   },

    };
    return axios
        .post(
            baseApiURL + "/api/Candidate/GeneratePdfWithQR?EncuserId=" + encodeURIComponent(userId),
            fileData,
            options
        )
      /*  .then((response) => response.json())*/
        .then((data) => {
            return data.data.result;
        })
        .catch((error) => {
            return (error);
        });
}
