import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

export default function DatePickerCustom(props) {
    function dateChangeEvent(date) {
        date.setHours(date.getHours() + 6);
        props.onChange(date, props.label);
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} variant={"filled"} className="">
            <KeyboardDatePicker
                orientation="portrait"
                fullWidth
                className="light-theme-date"
                clearable
                invalidLabel="Invalid Date"
                emptyLabel={props.placeholder}
                value={props.value}
                onChange={dateChangeEvent}
                format="dd/MM/yyyy"
                //format="MM/dd/yyyy"
                disableFuture={props.disablefuturedate}
                disablePast={props.disablepastdate}
                //shouldDisableDate={(date) => DisableBeforeAndSpecific(date, props.disableBefore, props.isSpecificDateDisable)}
                disabled={props.disabled}
                label={props.label}
                required={props.required}
                helperText={props.helperText}
                variant="inline"
                InputProps={{ readOnly: true }}
                views={["year", "month", "date"]}
                minDate={props.minDate}
                maxDate={props.maxDate}
            />
        </MuiPickersUtilsProvider>
    );
}
