import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Container,
  TextField,
  Button,
  Tooltip,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import profilePic from "../../assets/images/sample-avatar.png";
import "./DigitalID.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  paper: {
    marginTop: `2em`,
    backgroundColor: `var(--light-sand)`,
    boxShadow: 0,
  },
  details: {
    backgroundColor: `var(--light-sand) !important`,
  },
  footer: {
    fontSize: `10px !important`,
    backgroundColor: `#FFF !important`,
  },
  footerblock: {
    paddingBottom: `8px`,
  },
}));

export default function DigitalID() {
  const classes = useStyles();
  const currDate = Date();
  useEffect(() => {}, []);

  return (
    <Container maxWidth="xs" className={classes.paper}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6} className="imgCont">
          <img
            src={profilePic}
            alt="Cognisphere"
            className=""
            height={150}
            width={140}
          />
        </Grid>
        <Grid item xs={6}>
          <div className="subTitle">Padmaraj M</div>
          <div className="contentBody">
            Service Engineer Employee of,{" "}
            <strong>Tata Consultancy Services</strong>
          </div>
          <div className="contentBody">
            (Authozized only when deputed by <strong>Siemens Limited</strong>)
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="subTitle">Education Qualification:</div>
          <div className="contentBody">
            B.Tech in Computer Science and Engineering with 25 years of experience
          </div>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <div className="subTitle">Training Status:</div>
        <div className="contentBody">
          <ul className={classes.details}>
            <li>MV AIS validity till</li>
            <li>MV GIS validity till</li>
            <li>RMU validity till</li>
            <li>CSS validity till</li>
            <li>LVDs validity till</li>
            <li>Protection Competence Level Type</li>
            <li>EHS-SITRUST validity till</li>
          </ul>
        </div>
      </Grid>

      <footer className={classes.footerblock}>
        <p className={classes.footer}>Information Updated on: {currDate}</p>
      </footer>
    </Container>
  );
}
