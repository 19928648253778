import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector } from "react-redux";
import {
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from "@material-ui/core";
import "./FileUploadDialog.scss";
import { useDispatch } from "react-redux";
import { uploadImageRadioAction } from "../../Redux/Actions/actions";

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

export default function FileUploadDialog(props) {
    console.log("props", props);
    const classes = useStyles();
    const inputFileValue = Date.now;
    const dispatch = useDispatch();

    const [selectedValue, setSelectedValue] = useState("");
    const uploadType = useSelector(
        (state) => state.uploadImageRadioReducer.value
    );

    const handleChange = (event) => {
        const selValue = event.target.value;
        setSelectedValue(selValue);
        dispatch(uploadImageRadioAction(selValue));
        console.log("Val", selValue);
    };

   

    //const uploadOnClick = () => {
    //    console.log("Selected Image", uploadedImageFile);
    //    if (uploadType == "") {
    //        handleSnackOpen("Select a type of Image.", "warning");
    //        return;
    //    } else if (uploadedImageFile.length <= 0) {
    //        handleSnackOpen("Select an image file.", "warning");
    //        return;
    //    }

    //    setIsLoader(true);
    //    const formData = new FormData();
    //    formData.append("data", uploadedImageFile[0]);
    //    formData.append("CandidateId", candidateID);
    //    formData.append("Guid", guid);
    //    formData.append("UploadType", uploadType);
    //    formData.append("UserId", userDetails.userId);

    //    UploadImageAPI(token, formData)
    //        .then((response) => {
    //            handleSnackOpen("Image Uploaded Successfully", "success");
    //            getCandidateData();
    //            dispatch(uploadImageRadioAction(""));
    //            setFileUploadOPen(false);
    //            setIsLoader(false);
    //        })
    //        .catch((e) => {
    //            console.log("Error", e);
    //            handleSnackOpen("Error in Image Upload", "error");
    //            dispatch(uploadImageRadioAction(""));
    //            setFileUploadOPen(false);
    //            setIsLoader(false);
    //        });
    //};
    
    useEffect(() => {
        if (uploadType) {
            setSelectedValue(uploadType);
        }
        console.log("selectedValue", selectedValue);

    }, []);

    return (
        <>
            <Dialog
                width={"sm"}
                fullWidth={"lg"}
                maxWidth={"sm"}
                open={props.open}
                onClose={props.handleClose}
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Upload Image</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid xs={12} container>
                            <RadioGroup
                                row
                                aria-label="position"
                                name="position"
                                value={selectedValue}
                                onChange={handleChange}
                                defaultValue=""
                            >
                                <FormControlLabel
                                    value="PH"
                                    control={<Radio color="primary" />}
                                    label="User Image"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="QR"
                                    control={<Radio color="primary" />}
                                    label="QR Image"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid xs={12} container>
                            <Grid item xs={12} sm={6} md={8}>
                                <form className="file-upload-form">
                                    <TextField
                                        type="file"
                                        label="Upload File"
                                        variant="filled"
                                        className="file-upload"
                                        name="fileUpload"
                                        onChange={props.updateFileHandler}
                                        key={inputFileValue}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Button
                                    className="pt-button--primary bulk-upload-submit-btn"
                                    onClick={props.uploadOnClick}
                                    fullWidth
                                >
                                    Upload
                                </Button>
                            </Grid>
                        </Grid>
                        <span>Upload only image files.</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid xs={12} sm={6} md={6}>
                        <Button
                            onClick={props.handleClose}
                            className="pt-button--secondary bulk-upload-submit-btn"
                            fullWidth
                        >
                            Close
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}
